import Base from "../../../../functions/Base";
import store from "../../../../store";

export default class ServicesFunctions extends Base {
  get(errorResponse, successResponse){
    this.instance.get('/admin-services').then(({data}) => {
      store.dispatch({
        type: "SET_SERVICES",
        payload: data.data
      });
      successResponse()
    }).catch(err => {
      if (err.response && err.response.data) {
        errorResponse(err.response.data.error)
      } else {
        errorResponse("Error inesperado, intentelo de nuevo");
      }
    });
  }

  show(id, successResponse, errorResponse){
    this.instance.get(`/admin-services/${id}`).then(({data}) => {
      successResponse(data)
    }).catch(({response}) => {
      console.log(response);
      if (response && response.data) {
        errorResponse(response.data.error)
      } else {
        errorResponse("Error inesperado, intentelo de nuevo");
      }
    });
  }

  store (data, successResponse, errorResponse){
    this.instance.post('/admin-services', data).then(({data}) => {
      store.dispatch({
        type: "SET_SERVICES",
        payload: data.data
      });
      successResponse("Servicio creado correctamente.")
    }).catch(err => {
      if (err.response && err.response.data) {
        errorResponse(err.response.data.error)
      } else {
        errorResponse("Error inesperado, intentelo de nuevo");
      }
    })
  }

  update ( data, successResponse,  errorResponse){
    this.instance.patch(`/admin-services/${data.id}`, data).then(({data}) => {
      store.dispatch({
        type: "SERVICE_EDIT",
        payload: data.data
      });
      successResponse("Servicio editado correctamente.");
    }).catch(({response}) => {
      if (response && response.data) {
        if (typeof response.data.error !== "object") {
          errorResponse(response.data.error)
        } else {
          let errors = response.data.error
          let object = Object.values(errors)
          let res = '';
          object.map((key, index) => {
            if (index === 0) {
              res = key.toString();
            }
          })
          errorResponse(res);
        }
      } else {
        errorResponse("Error inesperado, intentelo de nuevo");
      }
    })
  }

  delete (data, successResponse, errorResponse){
    this.instance.delete(`admin-services/${data.id}`)
      .then(({data}) => {
        store.dispatch({
          type: "SERVICE_DELETE",
          payload: data.data
        });
        successResponse("Servicio eliminado correctamente.");
      })
      .catch(({response}) => {
        if (response && response.data) {
          errorResponse(response.data.error)
        } else {

          errorResponse("Error inesperado, intentelo de nuevo");
        }
      })
  }

  getCategories(successResponse,  errorResponse) {
    this.instance.get("admin-categories-all")
      .then(({data}) => {
        successResponse(data);
      })
      .catch(({response}) => {
        errorResponse("Error al cargar las categorias");
      })
  }

  resetServices(id, successResponse, errorResponse){
    this.instance.patch(`admin-reset-services/${id}`)
      .then(({data}) => {
        successResponse(data.data)
      })
      .catch(({response}) => {
        console.log(response);
        errorResponse("Error inesperado")
      });
  }
  getApis(successResponse) {
    this.instance.get("/admin-all-apis")
      .then(({data}) => {
        console.log(data);
        successResponse(data);
      })
      .catch(({response}) => {
        console.log(response);
      })
  }

  getApi(id, successResponse){
    this.instance.get(`admin-apis/${id}`)
      .then(({data}) => {
        console.log(data)
        successResponse(data)
      })
      .catch(err => {
        console.log("functions", err);
      })
  }
}