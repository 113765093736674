import Base from "../../../../functions/Base";
import store from "../../../../store";

export default class UserServiceFunctions extends Base {
  get(successResponse, errorResponse){
    this.instance.get('/services').then(({data}) => {
      store.dispatch({
        type: "SET_USER_SERVICE",
        payload: data.data
      });
      successResponse && successResponse(data)
    }).catch(err => {
      if (err.response && err.response.data) {
        errorResponse(err.response.data.error)
      } else {
        errorResponse && errorResponse("Error inesperado, intentelo de nuevo");
      }
    });
  }

  show(id, successResponse, errorResponse){
    this.instance.get(`/admin-apis/${id}`).then(({data}) => {
      successResponse(data)
    }).catch(({response}) => {
      if (response && response.data) {
        errorResponse(response.data.error)
      } else {
        errorResponse("Error inesperado, intentelo de nuevo");
      }
    });
  }

  store (data, successResponse, errorResponse){
    this.instance.post('/orders-history', data).then(({data}) => {
      successResponse("Orden creada.")
    }).catch(err => {
      console.log(err);
      if (err.response && err.response.data) {
        errorResponse(err.response.data.error)
      } else {
        errorResponse("Error inesperado, intentelo de nuevo");
      }
    })
  }

  update ( data, successResponse,  errorResponse){
    this.instance.patch(`/admin-apis/${data.id}`, data).then(({data}) => {
      store.dispatch({
        type: "API_EDIT",
        payload: data.data
      });
      successResponse("Api editada correctamente.");
    }).catch(({response}) => {
      if (response && response.data) {
        if (typeof response.data.error !== "object") {
          errorResponse(response.data.error)
        } else {
          let errors = response.data.error
          let object = Object.values(errors)
          let res = '';
          object.map((key, index) => {
            if (index === 0) {
              res = key.toString();
            }
          })
          errorResponse(res);
        }
      } else {
        errorResponse("Error inesperado, intentelo de nuevo");
      }
    })
  }

  delete (data, successResponse, errorResponse){
    this.instance.delete(`admin-apis/${data.id}`)
      .then(({data}) => {
        store.dispatch({
          type: "API_DELETE",
          payload: data.data
        });
        successResponse("Api eliminada correctamente.");
      })
      .catch(({response}) => {
        if (response && response.data) {
          errorResponse(response.data.error)
        } else {

          errorResponse("Error inesperado, intentelo de nuevo");
        }
      })
  }

  sync(id, successResponse, errorResponse){
    this.instance.get(`admin-apis-sinc/${id}`)
      .then(({data}) => {
        successResponse(data);
      })
      .catch(err => {
        console.log(err);
        errorResponse(err);
      })
  }
}