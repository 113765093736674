import { combineReducers } from "redux";
import AuthReducer from "./AuthReducer";
import CategoryReducer from "./CategoryReducer";
import ServiceReducer from "./ServiceReducer";
import UserReducer from "./UserReducer";
import BillReducer from "./BillReducer";
//import OrderUserReducer from "./OrderUserReducer";
//import BillUserReducer from "./BillUserReducer";
import OrderReducer from "./OrderReducer";
import ApiReducer from "./ApiReducer";
import UserServiceReducer from "./UserServiceReducer";
import DatabasesReducer from "./DatabasesReducer";
//import AdminUserOrderReducer from "./AdminUserOrderReducer";

const AllReducers = combineReducers({
  auth: AuthReducer,
  categories: CategoryReducer,
  services: ServiceReducer,
  users: UserReducer,
  bills: BillReducer,
  apis: ApiReducer,
//  order_user: OrderUserReducer,
 // bill_user: BillUserReducer,
  orders: OrderReducer,
  userServices: UserServiceReducer,
  databases: DatabasesReducer
  //admin_user_orders: AdminUserOrderReducer
});

export default AllReducers;