import React, {Component} from 'react';
import PanelHeader from "../../../components/PanelHeader/PanelHeader";
import { Card, CardBody, Table, CardHeader, CardTitle, Col} from "reactstrap";
import {thead} from "../../../variables/general";
import CustomPagination from "../../../components/pagination/Pagination";
import {connect} from "react-redux";
import moment from "moment";
import Loading from "../../../components/Loading";
import UserHistoryFunctions from "./functions/UserHistoryFunctions";
import ProfileFunctions from "../../Pages/functions/ProfileFunctions";

class UserBillsHistory extends Component {
  constructor() {
    super();
    this.state={
      isLoading: false,
      imei:"",
      service_id:"",
      status:""
    }
  }

  componentDidMount() {
    this.setState({ isLoading: true});
    new UserHistoryFunctions().getBills(
      data => this.successCallback(data),
      data => this.errorCallback(data),
    )
    new ProfileFunctions().onlyGet();
  }
  changeIsLoading = () => this.setState({isLoading: !this.state.isLoading});

  successCallback = () => this.changeIsLoading();

  errorCallback = (data) => {
    console.log("Error: ", data)
    this.changeIsLoading();
  }

  render() {
    const {bills} = this.props;
    console.log(this.state.isLoading);
    const {imei, service_id, status} = this.state;
    let dataSend = {imei, service_id, status};
    return (
      <>
        <PanelHeader size="sm" />
        <div className="content ">
          <Card className={'pt-3'}>
            <Col xs={12}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">
                    <div className={"justify-content-between align-content-between text-center"}>
                      Facturas
                    </div>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                    <tr>
                      <th>Cantidad</th>
                      <th className="text-right">Fecha</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                      bills.data.map(prop => {
                        return (
                          <tr key={prop.id}>
                            <td>${prop.amount}</td>
                            <td className={'text-right'}>{moment(prop.created_at).format('MMMM Do YYYY, h:mm:ss a')}</td>
                          </tr>
                        )
                      })
                    }
                    </tbody>
                    <tfoot>
                    <CustomPagination type={"SET_USER_BILLS"} change={this.changeIsLoading} links={bills.links} />
                    </tfoot>
                  </Table>
                </CardBody>
                {
                  /**
                   *<Loading isLoading={this.state.isLoading} />
                   */
                }
              </Card>
            </Col>
          </Card>
        </div>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    bills: state.users.userBills
  }
}

export default connect(mapStateToProps)(UserBillsHistory);