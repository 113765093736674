const ApiReducer = (state = {}, actions) => {
  switch (actions.type) {
    case "SET_APIS":
      return {
        ...state,
        apis: actions.payload
      };
    case "SET_API":
      return {
        ...state,

      };
    case "API_EDIT":
      let ap = state.apis.data.map(api => {
        if (api.id === actions.payload.id) {
          return actions.payload
        } else {
          return api
        }
      })
      return {
        ...state,
        apis: {
          ...state.apis,
          data: ap
        }
      }
    case "API_DELETE":
      let a = state.apis.data.filter(api => {
        return api.id !== actions.payload.id;
      })
      return {
        ...state,
        apis: {
          ...state.apis,
          data: a
        }
      }
    default:
      return state;
  }
};
export default ApiReducer;