
import Dashboard from "views/Dashboard/Dashboard.js";
import Buttons from "views/Components/Buttons.js";
import GridSystem from "views/Components/GridSystem.js";
import Panels from "views/Components/Panels.js";
import SweetAlert from "views/Components/SweetAlertPage.js";
import Notifications from "views/Components/Notifications.js";
import Icons from "views/Components/Icons.js";
import Typography from "views/Components/Typography.js";
import RegularForms from "views/Forms/RegularForms.js";
import ExtendedForms from "views/Forms/ExtendedForms.js";
import ValidationForms from "views/Forms/ValidationForms.js";
import Wizard from "views/Forms/Wizard/Wizard.js";
import RegularTables from "views/Tables/RegularTables.js";
import ExtendedTables from "views/Tables/ExtendedTables.js";
import ReactTable from "views/Tables/ReactTable.js";
import GoogleMaps from "views/Maps/GoogleMaps.js";
import FullScreenMap from "views/Maps/FullScreenMap.js";
import VectorMap from "views/Maps/VectorMap.js";
import Charts from "views/Charts/Charts.js";
import Calendar from "views/Calendar/Calendar.js";
import Widgets from "views/Widgets/Widgets.js";
import UserPage from "views/Pages/UserPage.js";
import TimelinePage from "views/Pages/TimelinePage.js";
import RTL from "views/Pages/RTL.js";
import Categories from "./views/admin/categories/Categories";
import Login from "./views/auth/Login";
import Service from "./views/admin/services/Service";
import Apis from "./views/admin/apis/Apis";
import Bills from "./views/admin/bills/Bills";
import Orders from "./views/admin/orders/Orders";
import UserServices from "./views/users/services/UserServices";
import SignIn from "./views/auth/SignIn"
import Users from "./views/admin/users/Users";
import EditUser from "./views/admin/users/EditUser";
import BillUserHistory from "./views/admin/users/BillUserHistory";
import OrderUserHistory from "./views/admin/users/OrderUserHistory";
import UserBillsHistory from "./views/users/history/UserBillsHistory";
import UserOrderHistory from "./views/users/history/UserOrderHistory";
import OrderPending from "./views/admin/orders/OrderPending";
import UserCustomPrices from "./views/admin/users/UserCustomPrices";
import Database from "./views/admin/databases/Database";
import AdminDashboard from "./views/Dashboard/AdminDashboard";

let routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "now-ui-icons design_app",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/admin-dashboard",
    name: "Admin Dashboard",
    icon: "now-ui-icons design_app",
    component: AdminDashboard,
    layout: "/admin",
    admin: true,
  },
  {
    path: "/admin-apis",
    name: "Apis",
    icon: "now-ui-icons design_vector",
    component: Apis,
    layout: "/admin",
    admin: true,
  },
  {
    path: "/admin-categories",
    name: "Categorias",
    icon: "now-ui-icons location_map-big",
    component: Categories,
    layout: "/admin",
    admin: true,
  },
  {
    path: "/admin-services",
    name: "Servicios",
    icon: "now-ui-icons shopping_basket",
    component: Service,
    layout: "/admin",
    admin: true,
  },
  {
    path: "/admin-bills",
    name: "Facturas",
    icon: "now-ui-icons files_paper",
    component: Bills,
    layout: "/admin",
    admin: true,
  },
  {
    path: "/users",
    name: "Usuarios",
    icon: "now-ui-icons users_single-02",
    component: Users,
    layout: "/admin",
    admin: true,
  },
  {
    path: "/databases",
    name: "Bases Custom",
    icon: "now-ui-icons text_bold",
    component: Database,
    layout: "/admin",
    admin: true,
  },
  {
    path: "/admin-user/:id",
    name: "Editar Usuarios",
    icon: "now-ui-icons design_app",
    component: EditUser,
    layout: "/admin",
    redirect: true
  },
  {
    path: "/admin-bill-user/:id",
    name: "BillUser",
    icon: "now-ui-icons design_app",
    component: BillUserHistory,
    layout: "/admin",
    redirect: true
  },
  {
    path: "/admin-orders-user/:id",
    name: "OrderUser",
    icon: "now-ui-icons design_app",
    component: OrderUserHistory,
    layout: "/admin",
    redirect: true
  },
  {
    path: "/admin-custom-prices/:id",
    name: "Custom Prices",
    icon: "now-ui-icons design_app",
    component: UserCustomPrices,
    layout: "/admin",
    redirect: true
  },
  /* {
     path: "/order-history",
     name: "Historial Admin",
     icon: "now-ui-icons design_bullet-list-67",
     component: Orders,
     layout: "/admin",
     admin: true,

   },
   {
     path: "/order-pending",
     name: "Ordenes Pendientes",
     icon: "now-ui-icons media-1_button-pause",
     layout: "/admin",
     component: OrderPending,
     admin: true,
   },*/
  {
    path: "/services",
    name: "Servicios",
    icon: "now-ui-icons shopping_shop",
    component: UserServices,
    layout: "/admin",
  },
  {
    path: "/history",
    name: "Historial",
    icon: "now-ui-icons design_bullet-list-67",
    component: UserOrderHistory,
    layout: "/admin",
  },
  {
    path: "/bills",
    name: "Mis Facturas",
    icon: "now-ui-icons shopping_credit-card",
    component: UserBillsHistory,
    layout: "/admin",
  },
  {
    collapse: true,
    path: "/pages",
    name: "Pages",
    state: "openPages",
    icon: "now-ui-icons design_image",
    redirect: true,
    views: [
      {
        path: "/timeline-page",
        name: "Timeline Page",
        mini: "TP",
        component: TimelinePage,
        layout: "/admin",
      },
      {
        path: "/user-page",
        name: "User Profile",
        mini: "UP",
        component: UserPage,
        layout: "/admin",
      },
      {
        path: "/rtl-support",
        name: "RTL Support",
        mini: "RS",
        component: RTL,
        layout: "/admin",
      },
      {
        path: "/signin",
        name: "Sign In",
        short: "SignIn",
        mini: "SI",
        component: SignIn,
        layout: "/auth",
      },
      {
        path: "/login-page",
        name: "Login Page",
        short: "Login",
        mini: "LP",
        component: Login,
        layout: "/auth",
      },
    ],
  },
  {
    collapse: true,
    path: "/components",
    name: "Components",
    state: "openComponents",
    icon: "now-ui-icons education_atom",
    redirect: true,
    views: [
      {
        path: "/buttons",
        name: "Buttons",
        mini: "B",
        component: Buttons,
        layout: "/admin",
      },
      {
        path: "/grid-system",
        name: "Grid System",
        mini: "GS",
        component: GridSystem,
        layout: "/admin",
      },
      {
        path: "/panels",
        name: "Panels",
        mini: "P",
        component: Panels,
        layout: "/admin",
      },
      {
        path: "/sweet-alert",
        name: "Sweet Alert",
        mini: "SA",
        component: SweetAlert,
        layout: "/admin",
      },
      {
        path: "/notifications",
        name: "Notifications",
        mini: "N",
        component: Notifications,
        layout: "/admin",
      },
      {
        path: "/icons",
        name: "Icons",
        mini: "I",
        component: Icons,
        layout: "/admin",
      },
      {
        path: "/typography",
        name: "Typography",
        mini: "T",
        component: Typography,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    path: "/forms",
    name: "Forms",
    state: "openForms",
    icon: "now-ui-icons design_bullet-list-67",
    redirect: true,
    views: [
      {
        path: "/regular-forms",
        name: "Regular Forms",
        mini: "RF",
        component: RegularForms,
        layout: "/admin",
      },
      {
        path: "/extended-forms",
        name: "Extended Forms",
        mini: "EF",
        component: ExtendedForms,
        layout: "/admin",
      },
      {
        path: "/validation-forms",
        name: "Validation Forms",
        mini: "VF",
        component: ValidationForms,
        layout: "/admin",
      },
      {
        path: "/wizard",
        name: "Wizard",
        mini: "W",
        component: Wizard,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    path: "/tables",
    name: "Tables",
    state: "openTables",
    icon: "now-ui-icons files_single-copy-04",
    redirect: true,
    views: [
      {
        path: "/regular-tables",
        name: "Regular Tables",
        mini: "RT",
        component: RegularTables,
        layout: "/admin",
      },
      {
        path: "/extended-tables",
        name: "Extended Tables",
        mini: "ET",
        component: ExtendedTables,
        layout: "/admin",
      },
      {
        path: "/react-table",
        name: "React Table",
        mini: "RT",
        component: ReactTable,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    path: "/maps",
    name: "Maps",
    state: "openMaps",
    icon: "now-ui-icons location_pin",
    redirect: true,
    views: [
      {
        path: "/google-maps",
        name: "Google Maps",
        mini: "GM",
        component: GoogleMaps,
        layout: "/admin",
      },
      {
        path: "/full-screen-maps",
        name: "Full Screen Map",
        mini: "FSM",
        component: FullScreenMap,
        layout: "/admin",
      },
      {
        path: "/vector-maps",
        name: "Vector Map",
        mini: "VM",
        component: VectorMap,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/widgets",
    name: "Widgets",
    icon: "now-ui-icons objects_diamond",
    component: Widgets,
    layout: "/admin",
    redirect: true,
  },
  {
    path: "/charts",
    name: "Charts",
    icon: "now-ui-icons business_chart-pie-36",
    component: Charts,
    layout: "/admin",
    redirect: true,
  },
  {
    path: "/calendar",
    name: "Calendar",
    icon: "now-ui-icons media-1_album",
    component: Calendar,
    layout: "/admin",
    redirect: true,
  },
];

export default routes;
