export const statusOrder = (status) => {
  let res = {text: "En Proceso", color: "warning"}
  if (status === "3") {
    res = {text: "Rechazado", color: "danger"}
  } else if (status === "4"){
    res = {text: "Completa", color: "success"}
  }
  return res;
}

export const badge = (status) => {
  let data = {color: 'info', status: "Pendiente"};
  switch (status){
    case "0":
      data = {color: 'info', status: "Pendiente"};
      break;
    case "1":
      data = {color: 'warning', status: "En Proceso"};
      break;
    case "3":
      data = {color: 'danger', status: "Rechazada"};
      break;
    case "4":
      data = {color: 'success', status: "Completada"};
      break;
    default:
      data = {color: 'info', status: "Pendiente"};
      break;
  }
  return data;
}