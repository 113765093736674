import React, {Component} from 'react';
import {
  Button,
  Card,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";

class CreateCategoryModal extends Component {
  constructor() {
    super();
    this.state={
      name:'',
    }
  }

  onChange = (e) => {
    this.setState({[e.target.name]: e.target.value});
  }

  onSubmit = (e) => {
    e.preventDefault();
    const {name} = this.state;
    const data = {name, type: "create"}
    this.props.onSubmit(data);
    this.props.toggle()
  }
  render() {
    const {isOpen, toggle} = this.props;
    return (
      <>
        <Modal
          isOpen={isOpen}
          toggle={toggle}
          onClosed={() => this.setState({name: ''})}
          modalClassName={"modal-primary "}
        >
          <Card className="card-plain card-login">
            <ModalHeader className="justify-content-center" toggle={toggle} tag="div">
              <div className="header text-center ">
                <h2 >
                  Crear Categoria
                </h2>
              </div>
            </ModalHeader>
            <ModalBody className="card-content">
              <form className="form" onSubmit={e => this.onSubmit(e)}>
                <div >
                    <Label className={"text-white"}>Nombre</Label>
                    <Input
                      type="text"
                      placeholder="Nombre de la Categoria"
                      name={'name'}
                      onChange={this.onChange}
                      autoComplete={'off'}
                    />
                </div>
              </form>
            </ModalBody>
            <ModalFooter>
              <Button className={"btn-round"} color="secondary" onClick={toggle}>
                Cerrar
              </Button>
              <Button
                disabled={!this.state.name}
                className={"btn btn-dark btn-round"}
                onClick={e => this.onSubmit(e)}
                type={'submit'}
              >
                Guardar Cambios
              </Button>
            </ModalFooter>

          </Card>
        </Modal>
      </>

    );
  }
}

export default CreateCategoryModal;