import React from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Form,
  Container,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
} from "reactstrap";

// core components
import nowLogo from "assets/img/fenox/fenox-corp.png";

import bgImage from "assets/img/stats2.jpg";
import {Link} from "react-router-dom";
import axios from "axios";
import cookie from "js-cookie";
import store from "../../store";
import Loading from "../../components/Loading";
import {api} from "../../functions/Base";
import ProfileFunctions from "../Pages/functions/ProfileFunctions";

class Login extends React.Component {
  constructor() {
    super();
    this.state={
      email:'',
      password:'',
      error:'',
      isLoading:false
    }
  }

  componentDidMount() {
    let token = cookie.get("token");
    if (token) {
      new ProfileFunctions().get(
        () => this.onSuccessProfile() ,
        () => this.onErrorProfile());
    }
  }

  onSuccessProfile = () => console.log('login')
  onErrorProfile = () => console.log('login')

  onSubmit = async(e) => {
    e.preventDefault()
    this.setState({isLoading: true, error:''});
    const {email, password} = this.state;
    if (email === '') {
      this.setState({error: "El campo email no puede ir vacio"});
    } else if (password === '') {
      this.setState({error: "El campo password no puede ir vacio"});
    } else {
      const data = {email, password};
      await axios.post(`${api}/login`, data).then(res => {
        cookie.set("token", res.data.data.token);
        store.dispatch({
          type: "SET_LOGIN",
          payload: res.data.data
        });
      }).catch(err => {
        console.log( err);
        if (err.response && err.response.data) {
          this.setState({error: err.response.data.error})
        }
      })
    }
    this.setState({isLoading: false});
  }

  onChange = (e) => {this.setState({[e.target.name]: e.target.value, error:''})}

  render() {
    const {error, isLoading} = this.state;
    return (
      <>
        <div className="content">
          <div className="login-page">
            <Container>
              <Col xs={12} md={8} lg={4} className="ml-auto mr-auto">
                <Form onSubmit={e => this.onSubmit(e)}>
                  <Card className="card-login card-plain">
                    <CardHeader>
                      <div className="logo-container">
                        <img src={nowLogo} alt="now-logo"/>
                      </div>
                    </CardHeader>
                    <CardBody>
                      {
                        error && <h5 className={"text-danger text-center"}>{error}</h5>
                      }
                      <InputGroup
                        className={
                          "no-border form-control-lg "
                        }
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons ui-1_email-85"/>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="text"
                          name={'email'}
                          onChange={this.onChange}
                          placeholder="Email..."
                        />
                      </InputGroup>
                      <InputGroup
                        className={
                          "no-border form-control-lg "
                        }
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons ui-1_lock-circle-open"/>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="password"
                          name={'password'}
                          onChange={this.onChange}
                          placeholder="Contraseña..."
                        />
                      </InputGroup>

                    </CardBody>
                    <CardFooter>
                      <Button
                        onClick={(e) => this.onSubmit(e)}
                        block
                        color="primary"
                        size="sn"
                        className="mb-3 btn-round"
                        type={'submit'}
                      >
                        COMENZAMOS
                      </Button>

                      <div className="text-center">
                        <h6>
                          <Link to="/auth/register-page" className="link footer-link">
                            ¿AUN NO TIENES UNA CUENTA? CREALA AQUI!
                          </Link>
                        </h6>
                      </div>
                    </CardFooter>
                  </Card>
                </Form>
              </Col>
            </Container>
          </div>
        </div>
        <div
          className="full-page-background"
          style={{backgroundImage: "url(" + bgImage + ")"}}
        />
        <Loading isLoading={isLoading} />
      </>
    );
  }
}

export default Login;
