const DatabasesReducer = (state = {}, actions) => {
  switch (actions.type) {
    case "SET_DATABASES":
      return {
        ...state,
        databases: actions.payload
      };
    case "SET_DATABASE":
      return {
        ...state,

      };
    case "DATABASE_EDIT":
      let cate = state.databases.data.map(database => {
        if (database.id === actions.payload.id) {
          return actions.payload
        } else {
          return database
        }
      })
      return {
        ...state,
        databases: {
          ...state.databases,
          data: cate
        }
      }
    case "DATABASE_DELETE":
      let categ = state.databases.data.filter(database => {
        return database.id !== actions.payload.id;
      })
      return {
        ...state,
        databases: {
          ...state.databases,
          data: categ
        }
      }
    default:
      return state;
  }
};
export default DatabasesReducer;