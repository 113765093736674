import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

const GuestRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}

      render={props =>

        !rest.loggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: rest.user.admin === "true" ? "/admin/admin-dashboard" : "/admin/dashboard",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};
const mapStoreToProps = state => {
  return {
    loggedIn: state.auth.loggedIn,
    user: state.auth.user
  };
};
export default connect(mapStoreToProps)(GuestRoute);