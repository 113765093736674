import React from "react";
// reactstrap components
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Container,
  Row,
  Col,
  Form,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Button,
} from "reactstrap";

// core components
import bgImage from "assets/img/stats3.jpg";
import axios from "axios";
import cookie from "js-cookie";
import store from "../../store";
import {api} from "../../functions/Base";



function SignIn() {

  const [nameFocus, setNameFocus] = React.useState(false);
  const [passwordFocus, setPasswordFocus] = React.useState(false);
  const [passwordConfirmFocus, setPasswordConfirmFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  const [phoneFocus, setPhoneFocus] = React.useState(false);
  const [name, setName] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [passwordConfirm, setPasswordConfirm] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [error, setError] = React.useState('');

  React.useEffect(() => {
    document.body.classList.add("register-page");
    return function cleanup() {
      document.body.classList.remove("register-page");
    };
  }, []);

  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  async function signIn () {
    const password_confirmation = passwordConfirm
    const data = {email, phone,  name, password, password_confirmation};
    return await axios.post(`${api}/register`, data)
      .then(({data}) => {
        cookie.set("token", data.data.token);
        store.dispatch({
          type: "SET_LOGIN",
          payload: data.data
        });
      })
      .catch(({response}) => {
        console.log( "response: ", response);
        if (response && response.data) {
          setError(response.data.errors);
        }
      });
  }

  function onSubmit(e) {
    e.preventDefault();
    setError('');
    if(email === "" || password === "" || passwordConfirm === "" || name === "") {
      setError('Todos los campos son obligatorios');
    }
    else if(password !== passwordConfirm) {
      setError('El campo password y confirmar password deben ser iguales');
    }
    else if(password.length < 6) {
      setError('El password debe contar con al menos 6 caracteres');
    }
    else if(!validateEmail(email)) {
      setError('El email es un formato invalido');
    }
    else {
      signIn();
    }
  }

  return (
    <>
      <div className="content">
        <div className="register-page">
          <Container>
            <Row className="justify-content-center">
              <Col lg={5} md={8} xs={12}>
                <div className="info-area info-horizontal mt-5">
                  <div className="icon icon-primary">
                    <i className="now-ui-icons media-2_sound-wave" />
                  </div>
                  <div className="description">
                    <h5 className="info-title">Productividad</h5>
                    <p className="description">
                      Podras atender a mas clientes con el mismo personal.
                    </p>
                  </div>
                </div>
                <div className="info-area info-horizontal">
                  <div className="icon icon-primary">
                    <i className="now-ui-icons media-1_button-pause" />
                  </div>
                  <div className="description">
                    <h5 className="info-title">Eficacia</h5>
                    <p className="description">
                      Disminuye el margen de error gracias a procesos guiados.
                    </p>
                  </div>
                </div>
                <div className="info-area info-horizontal">
                  <div className="icon icon-info">
                    <i className="now-ui-icons users_single-02" />
                  </div>
                  <div className="description">
                    <h5 className="info-title">Eficiencia</h5>
                    <p className="description">
                      Responde a tus clientes más rapidamente y fidelizalos.
                    </p>
                  </div>
                </div>
              </Col>
              <Col lg={4} md={8} xs={12}>
                <Card className="card-signup">
                  <CardHeader className="text-center">
                    <CardTitle tag="h4">Registrate</CardTitle>
                    <div className="social btns-mr-5">
                      <h5 className="card-description">en nuestra plataforma</h5>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <InputGroup
                        className={nameFocus ? "input-group-focus" : ""}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons users_circle-08" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="text"
                          placeholder="Usuario..."
                          onFocus={(e) => setNameFocus(true)}
                          onBlur={(e) => setNameFocus(false)}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </InputGroup>
                      <InputGroup
                        className={emailFocus ? "input-group-focus" : ""}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons ui-1_email-85" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="email"
                          placeholder="Email..."
                          onFocus={(e) => setEmailFocus(true)}
                          onBlur={(e) => setEmailFocus(false)}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </InputGroup>
                      <InputGroup
                        className={phoneFocus ? "input-group-focus" : ""}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons tech_headphones" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="number"
                          placeholder="WhatsApp..."
                          onFocus={(e) => setPhoneFocus(true)}
                          onBlur={(e) => setPhoneFocus(false)}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </InputGroup>
                      <InputGroup
                        className={passwordFocus ? "input-group-focus" : ""}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons ui-1_lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="password"
                          placeholder="Contraseña..."
                          onFocus={(e) => setPasswordFocus(true)}
                          onBlur={(e) => setPasswordFocus(false)}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </InputGroup>
                      <InputGroup
                        className={passwordConfirmFocus ? "input-group-focus" : ""}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons ui-1_lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="password"
                          placeholder="Confirmar Contraseña..."
                          onFocus={(e) => setPasswordConfirmFocus(true)}
                          onBlur={(e) => setPasswordConfirmFocus(false)}
                          onChange={(e) => setPasswordConfirm(e.target.value)}
                        />
                      </InputGroup>
                    </Form>
                  </CardBody>
                  <CardFooter className="text-center">
                    {error.length ? (
                      <label className={'text-danger'}>{error}</label>
                    ): null}
                    <Button
                      color="primary"
                      size="lg"
                      className="btn-round"
                      onClick={(e) => onSubmit(e)}
                    >
                      Crear Cuenta
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div
        className="full-page-background"
        style={{ backgroundImage: "url(" + bgImage + ")" }}
      />
    </>
  );
}

export default SignIn;
