const ServiceReducer = (state = {}, actions) => {
  switch (actions.type) {
    case "SET_SERVICES":
      return {
        ...state,
        services: actions.payload
      };
    case "SET_SERVICE":
      return {
        ...state,
        service: actions.payload
      };
    case "SERVICE_EDIT":
      let ser = state.services.data.map(service => {
        if (service.id === actions.payload.id) {
          return actions.payload
        } else {
          return service
        }
      })
      return {
        ...state,
        services: {
          ...state.services,
          data: ser
        }
      }
    case "SERVICE_DELETE":
      let serv = state.services.data.filter(service => {
        return service.id !== actions.payload.id;
      })
      return {
        ...state,
        services: {
          ...state.services,
          data: serv
        }
      }

    default:
      return state;
  }
};
export default ServiceReducer;