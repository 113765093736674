import React, {Component} from 'react';
import {Badge, Label, Modal, ModalBody, ModalHeader} from "reactstrap";
import Footer from "../../../components/Footer/Footer";
import moment from "moment";
import ReactHtmlParser from "react-html-parser";

class ShowOrderModal extends Component {

  status = status => {
    let res = {text: "En Proceso", color: "warning"}
    if (status === "3") {
      res = {text: "Rechazado", color: "danger"}
    } else if (status === "4"){
      res = {text: "Completa", color: "success"}
    }
    return res;
  }

  formatDate = (created, updated) => {
    let created_at = moment(created);
    let updated_at = moment(updated);

    let diff = updated_at.diff(created_at, 'minutes');
    let res = `${diff} minutos`;
    if (diff > 60) {
      diff = updated_at.diff(created_at, 'hours');
      res = `${diff} horas`;
    }
    if (diff > 24) {
      diff = updated_at.diff(created_at, 'days');
      res = `${diff} dias`;
    }
    return res;
  }

  render() {
    const {isOpen, order, toggle} = this.props
    const status = this.status(order.status)
    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
      >
        <ModalHeader>
          {order && order.service && order.service.name}
        </ModalHeader>
        <ModalBody>
          <Label>
            Imei:
          </Label>
          <p>{order && order.imei}</p>
          <hr />
          {
            order && order.response &&
              <>
                <Label>
                  Repuesta:
                </Label>
                <p>{ReactHtmlParser(order.response)}</p>
                <hr />
              </>
          }

          <Label>Estatus: </Label>
          <p>
            {order &&
              <Badge
                color={status.color}
              >
                {status.text}
              </Badge>
            }
          </p>
          <hr />
          <Label>
            Fecha de encargo:
          </Label>
          <p>{order && moment(order.created_at).format('MMMM Do YYYY, h:mm:ss a')}</p>
          <hr />
          {
            order && order.response &&
              <>
                <Label> Fecha de respuesta</Label>
                <p>{order && moment(order.updated_at).format('MMMM Do YYYY, h:mm:ss a')}</p>
                <hr />
                <Label> Tiempo de respuesta:  </Label>
                <p className="col-md-2">{this.formatDate(order.created_at, order.updated_at)}</p>
                <hr />
            </>
          }
        </ModalBody>
        <Footer />
      </Modal>
    );
  }
}

export default ShowOrderModal;