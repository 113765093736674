import { createStore } from "redux";
import AllReducers from "./reducers/AllReducers";

const initialState = {
  auth: {
    loggedIn: false,
    user: {}
  },
  apis:{
    apis:{
      data:[],
      links:[]
    },
  },
  categories:{
    categories:{
      data:[],
      links:[]
    },
  },
  databases:{
    databases:{
      data:[],
      links:[]
    },
  },
  services:{
    services:{
      data:[],
      links:[]
    },
    service:[]
  },
  users:{
    users:{
      data:[],
      links:[]
    },
    userBills:{
      data:[],
      links:[]
    },
    userOrders:{
      data:[],
      links:[]
    },
    userServices:{
      data:[]
    }
  },
  bills:{
    bills:{
      data:[],
      links:[]
    },
    adminUserBill:{
      data:[],
      links:[]
    },
    bill:[]
  },
  services_user:{
    services_user:[]
  },
  order_user:{
    order_user: {
      data:[],
      links:[]
    }
  },
  bill_user:{
    bill_user: {
      data:[],
      links:[]
    }
  },
  orders:{
    orders:[],
    order_history:{
      data:[],
      links:[]
    },
    adminUserOrder:{
      data:[],
      links:[]
    },
  },
  admin_user_orders :{
    admin_user_orders:{
      data:[],
      links:[]
    }
  },
  userServices:{
    userServices:[]
  }
};
const store = createStore(
  AllReducers,
  initialState,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;