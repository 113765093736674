import React, {Component} from 'react';
import {
  Button,
  Card, Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";

class CreateApiModal extends Component {
  constructor() {
    super();
    this.state={
      name:'',
      url:'',
      api_key:'',
      username:'',
    }
  }

  onChange = (e) => this.setState({[e.target.name]: e.target.value});

  onSubmit = (e) => {
    e.preventDefault();
    const {name, url, api_key, username} = this.state;
    const data = {name,url, api_key, username, type: "create"}
    this.props.onSubmit(data);
    this.props.toggle()
  }
  render() {
    const {isOpen, toggle} = this.props;
    const {name, url, api_key, username} = this.state;
    return (
      <>
        <Modal
          isOpen={isOpen}
          toggle={toggle}
          onClosed={() => this.setState({name: '', url:'', api_key:'', username:''})}
          modalClassName={"modal-primary "}
        >
          <Card className="card-plain card-login">
            <ModalHeader className="justify-content-center" toggle={toggle} tag="div">
              <div className="header text-center ">
                <h2 >
                  Crear API
                </h2>
              </div>
            </ModalHeader>
            <ModalBody className="card-content">
              <form className="form" onSubmit={e => this.onSubmit(e)}>
                <Col >
                  <Label className={"text-white"}>Nombre</Label>
                  <Input
                    type="text"
                    placeholder="Nombre de la Api"
                    name={'name'}
                    onChange={this.onChange}
                    autoComplete={'off'}
                  />
                </Col>
                <Col >
                  <Label className={"text-white"}>URL</Label>
                  <Input
                    type="text"
                    placeholder="Url de la api"
                    name={'url'}
                    onChange={this.onChange}
                    autoComplete={'off'}
                  />
                </Col>
                <Col >
                  <Label className={"text-white"}>ApiKey</Label>
                  <Input
                    type="text"
                    placeholder="Api Key de la api"
                    name={'api_key'}
                    onChange={this.onChange}
                    autoComplete={'off'}
                  />
                </Col>
                <Col >
                  <Label className={"text-white"}>Username</Label>
                  <Input
                    type="text"
                    placeholder="Username de la api"
                    name={'username'}
                    onChange={this.onChange}
                    autoComplete={'off'}
                  />
                </Col>
              </form>
            </ModalBody>
            <ModalFooter>
              <Button className={"btn-round"} color="secondary" onClick={toggle}>
                Cerrar
              </Button>
              <Button
                disabled={!name || !url || !api_key || !username}
                className={"btn btn-dark btn-round"}
                onClick={e => this.onSubmit(e)}
                type={'submit'}
              >
                Guardar Cambios
              </Button>
            </ModalFooter>
          </Card>
        </Modal>
      </>
    );
  }
}

export default CreateApiModal;