import Base from "../../../functions/Base";


export default class DashboardFunctions extends Base {
  get(successResponse, errorResponse){
    this.instance.get('/user-dashboard').then(({data}) => {
     successResponse(data)
    }).catch(({response}) => {
      console.log(response)
     // errorResponse("Error inesperado, intentelo de nuevo");
    });
  }

  getAdmin(successResponse, errorResponse){
    this.instance.get('/admin-dashboard').then(({data}) => {
      successResponse( data)
    }).catch((response) => {
      console.log("admin: ", response, response.response)
      // errorResponse("Error inesperado, intentelo de nuevo");
    });
  }
}