import React, {Component} from 'react';
import PanelHeader from "../../../components/PanelHeader/PanelHeader";
import { Button, Card, CardBody, CardHeader, Col, Form, Input, Label, Row} from "reactstrap";
import UserFunctions from "./functions/UserFunctions";
import Loading from "../../../components/Loading";

class EditUser extends Component {
  constructor() {
    super();
    this.state={
      name: "",
      email: "",
      credits: "",
      api_key: "",
      active: "",
      id: '',
      password:'',
      actualCredits: 0,
      isLoading: false
    }
  }

  componentDidMount() {
    this.setUser();
  }

  setUser = () => {
    const user = this.props.location.state;
    const {name, email, api_key, id, credits } = user
    this.setState({name, email, credits: 0.00, api_key, id, actualCredits: credits })
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.state.id !== this.state.id) {
      this.setUser();
    }
  }


  onChange = e => {
    this.setState({[e.target.name]: e.target.value}, () => {
    });
  }

   validateEmail = (email) =>  {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  onSubmitGeneral = (e) => {
    e.preventDefault();
    this.setState({isLoading: true});
    const {name, email, id} = this.state;

    if (name === "" || email === "") {
      return alert("El campo email y nombre son requeridos.");
    } else if (!this.validateEmail(email)) {
      return alert("El email es un formato invalido.");
    } else {
      const data = {name, email, id};
      new UserFunctions().update(
        data,
        (message, data) => this.successResponse(message, data),
        data => this.errorResponse(data),
      )
    }
  }

  successResponse = (message, data) => {
    this.setState({isLoading: false});
    const {name, email, api_key, credits} = data.data;
    this.setState({name, email, api_key, actualCredits: credits, credits:0})
  }

  errorResponse = (data) => {
    this.setState({isLoading: false});
    return alert(data);
  }

  onSubmitPassword = (e) => {
    this.setState({isLoading: true});
    e.preventDefault();
   const {password, id} = this.state;
    const data = { password, id};
    new UserFunctions().update(
      data,
      data => this.successResponse(data),
      data => this.errorResponse(data),
    )

  }

  onSubmitApiKey = (e) => {
    this.setState({isLoading: true});
    e.preventDefault();
    const data = {api_key: true, id: this.state.id};
    new UserFunctions().update(
      data,
      (message, data) => this.successResponse(message, data),
      data => this.errorResponse(data),
    )
  }

  onSubmitCredits = (e) => {
    this.setState({isLoading: true});
    e.preventDefault();
    const {credits, id} = this.state;
    const data = {id, credits};
    new UserFunctions().update(
      data,
      (message, data) => this.successResponse(message, data),
      data => this.errorResponse(data),
    )
  }

  render() {
    const {name, email, credits, api_key, actualCredits, id} = this.state;
    const user = this.props.location.state;
    return (
      <div>
        <PanelHeader content={'hola'} size="sm" />
        <div className="content ">
          <Card>
            <CardHeader> {name} <hr /></CardHeader>
            <Row>
            <Col md={6} lg={6} sm={12} >
              <Card className={'pt-3'}>
                <CardHeader>
                  Informacion General
                </CardHeader>
                <CardBody>
                  <Form>
                    <Label>Nombre</Label>
                    <Input
                      name={'name'}
                      placeholder={'Nombre'}
                      type={'text'}
                      value={name}
                      onChange={this.onChange}
                    />
                    <Label>Email</Label>
                    <Input
                      name={'email'}
                      placeholder={'Email'}
                      type={'email'}
                      value={email}
                      onChange={this.onChange}
                    />
                    <Button
                      onClick={(e) => this.onSubmitGeneral(e)}
                      size={'sm'}
                      color={'primary'}
                    >
                      Enviar
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
              <Col md={6} lg={6} sm={12} >
                <Card className={'pt-3'}>
                  <CardHeader>
                    Cambiar Contraseña
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <Label>Contraseña</Label>
                      <Input
                        name={'password'}
                        placeholder={'Contraseña'}
                        type={'password'}
                        onChange={this.onChange}
                      />
                      <Button
                        onClick={(e) => this.onSubmitPassword(e)}
                        size={'sm'}
                        color={'primary'}
                      >
                        Cambiar Contraseña
                      </Button>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
          </Row>
            <Row>
              <Col md={6} lg={6} sm={12} >
                <Card className={'pt-3'}>
                  <CardHeader>
                    ApiKey
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <Label>ApiKey</Label>
                      <Input
                        name={'api_key'}
                        placeholder={'ApiKey'}
                        type={'text'}
                        disabled
                        value={api_key}
                      />
                      <Button
                        onClick={(e) => this.onSubmitApiKey(e)}
                        size={'sm'}
                        color={'primary'}
                      >
                        Cambiar Api Key
                      </Button>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
              <Col md={6} lg={6} sm={12} >
                <Card className={'pt-3'}>
                  <CardHeader>
                    Creditos
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <Label>Adherir Creditos <h6>Creditos actuales: {actualCredits}</h6></Label>
                      <Input
                        name={'credits'}
                        placeholder={'Creditos a añadir'}
                        type={'number'}
                        value={credits}
                        onChange={this.onChange}
                      />
                      <Button
                        onClick={(e) => this.onSubmitCredits(e)}
                        size={'sm'}
                        color={'primary'}
                      >
                        Añadir {credits} creditos
                      </Button>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md={6} lg={6} sm={12} >
                <Card className={'pt-3'}>
                  <CardHeader>
                    Historial de Facturas
                  </CardHeader>
                  <CardBody>
                    {
                      ///admin-bill-user/:id
                    }
                    <Button
                      size={'sm'}
                      color={'primary'}
                      onClick={() => this.props.history.push(`/admin/admin-bill-user/${user.id}`, user)}
                    >Ver Historial</Button>
                  </CardBody>
                </Card>
              </Col>
              <Col md={6} lg={6} sm={12} >
                <Card className={'pt-3'}>
                  <CardHeader>
                    Historial de Ordenes
                  </CardHeader>
                  <CardBody>
                    <Button
                      size={'sm'}
                      color={'primary'}
                      onClick={() => this.props.history.push(`/admin/admin-orders-user/${user.id}`, user)}
                    >Ver Historial</Button>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md={12} lg={12} sm={12} >
                <Card className={'pt-3'}>
                  <CardHeader className={'text-center'}>
                    Precios Custom
                  </CardHeader>
                  <CardBody className={'text-center'}>
                    <Button
                      size={'sm'}
                      color={'primary'}
                      onClick={() => this.props.history.push(`/admin/admin-custom-prices/${user.id}`, user)}
                    >
                      Cambiar los precios a este usuario
                    </Button>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Card>
          <Loading isLoading={this.state.isLoading} />
        </div>
      </div>
    );
  }
}

export default EditUser;