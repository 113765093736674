import React, {Component} from 'react';
import {Button, Modal, ModalFooter, ModalHeader} from "reactstrap";

class ActiveModal extends Component {

  onSubmit = e => {
    e.preventDefault();
    const {active, id} = this.props.user;
    const data = {
      id,
      active: active === "true" ? "false" : "true",
      type: "edit"
    }
    this.props.onsubmit(data);
    this.props.toggle();
  }
  render() {
    const {isOpen, toggle, user} = this.props;
    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
      >
        <ModalHeader>
          {user && user.active === "true" ?
            `¿Desactivar al usuario ${user && user.name && user.name}?` :
            `¿Activar al usuario ${user && user.name && user.name}?`}
        </ModalHeader>
        <ModalFooter>
          <Button
            size={'sm'}
            color={'danger'}
            onClick={toggle}
          >
            Cancelar
          </Button>
          <Button
            size={'sm'}
            color={'primary'}
            onClick={(e) => this.onSubmit(e)}
          >
            {user && user.active === "true" ? `Desactivar` : `Activar`}
          </Button>
        </ModalFooter>

      </Modal>
    );
  }
}

export default ActiveModal;