
import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
  Input,
  ListGroup,
  ListGroupItem,
  Badge,
  Alert
} from "reactstrap";

// core components
import bgImage from "assets/img/bg15.jpg";
import PanelHeader from "../../../components/PanelHeader/PanelHeader";
import {connect} from "react-redux";
import UserServiceFunctions from "./functions/UserServiceFunctions";
import ShowServiceModal from "./ShowServiceModal";
import {timeOut} from "../../../functions/Base";
import ProfileFunctions from "../../Pages/functions/ProfileFunctions";

class UserServices extends React.Component {
  constructor() {
    super();
    this.state={
      isLoading: false,
      userServices:[],
      filterValues:[],
      showServiceModal: false,
      service:[],
      colorAlert: 'primary',
      visible: false,
      alert:''
    }
  }

  componentDidMount() {
    this.setState({isLoading: true});
    new UserServiceFunctions().get(
      data => this.successResponse(data),
      data => this.errorResponse(data),
    )
    new ProfileFunctions().onlyGet();
  }

  toggleShowService = (service) => {
    this.setState({showServiceModal: !this.state.showServiceModal, service});
  }

  successResponse = (data) => {
    const userServices = data.data.filter(category => {
      return category.services.length > 0;
    });
    this.setState({userServices, filterValues: userServices, isLoading:false});
  }

  onFilter = (e) => {
    let categories = this.state.filterValues
    let value = e.target.value;
    let newCategories = [];
     categories.map(category => {
      let newServices = [];
      category.services.map(service => {
        if (service.name.toLowerCase().includes(value.toLowerCase()) || category.name.toLowerCase().includes(value.toLowerCase())) {
         newServices.push( service);
        }
      });
      newCategories.push({...category, services: newServices});
    })
     this.setState({userServices: newCategories});
  }

  errorResponse = (data) => {
    this.setState({
      isLoading: false,
      alert: data,
      colorAlert: "danger",
      visible: true,
    }, () => {
      setTimeout(() => {
        this.onDismiss()
      }, timeOut)
    });
  }

  onDismiss = () => {this.setState({visible: !this.state.visible, colorAlert: 'primary'})}

  onSubmitOrder = (dat) => {
   this.setState({isLoading: true});
    const data = {
      imeis: dat.imeis,
      qnt: dat.qnt,
      custom_fields: dat.custom_field,
      service_id: this.state.service.id
    }
    new UserServiceFunctions().store(
        data,
      data => this.successOnSubmit(data),
      data => this.errorResponse(data));
  }

  successOnSubmit = (message) => {
    this.setState({
      isLoading: false,
      alert: message,
      visible: true
    },  () => {
      setTimeout(() => {
        this.onDismiss()
      }, timeOut)
    });
  }

  render() {
    const {userServices, colorAlert, alert} = this.state;

    return (
      <>
        <PanelHeader size="sm" />
        <div className="content pt-5">
          <div className="pricing-page">
            <Container>
              {alert && <Alert color={colorAlert} isOpen={this.state.visible} toggle={() => this.onDismiss}>
                {alert}
              </Alert>}
              <Row>
                <Col xs={12} md={6} className="ml-auto mr-auto text-center">
                  <h5 className="description">
                    Buscar Servicio.
                  </h5>
                  <Input
                    onChange={this.onFilter}
                    color={'primary'}
                    className={""}
                    placeholder={"Buscar servicio..."}
                  />
                </Col>
              </Row>
                {
                  userServices &&
                  userServices.map(category => {
                      return (
                        <>
                          {
                            <>
                              {
                                category.services.length > 0 &&
                                <h3 className={'mt-2'} key={`category-${category.id}`} >{category.name} </h3>
                              }

                              <ListGroup lg={3} md={6} xs={12} key={category.id}>
                                <Row>
                                  {category.services.map(service => {

                                    return (
                                      service.active === "true" &&
                                      <Col md={6} xs={12} lg={3}>
                                        <ListGroupItem
                                          onClick={() => this.toggleShowService(service)}
                                          key={`service-name${service.id}`}
                                          action className={"rounded"}
                                        >
                                          <h6 key={`service-name${service.id}`}>{service.name}</h6>
                                          <div>
                                            <Badge className={"mr-1"} color={'warning'}>{service.time}</Badge>
                                            <Badge
                                              color={'info'}
                                            >
                                              ${service && service.owner && service.owner[0].pivot && service.owner[0].pivot.price }
                                            </Badge>
                                          </div>
                                        </ListGroupItem>
                                      </Col>
                                    )
                                  })}
                                </Row>
                              </ListGroup>
                            </>
                          }
                        </>
                      )

                  })
                }
            </Container>
          </div>
        </div>
        {
          /**
           *<Loading isLoading={this.state.isLoading} />
           */
        }

        <ShowServiceModal
          isOpen={this.state.showServiceModal}
          toggle={() => this.toggleShowService()}
          service={this.state.service}
          onSubmit={(data) => this.onSubmitOrder(data)}
        />

        <div
          className="full-page-background"
          style={{backgroundImage: "url(" + bgImage + ")"}}
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    userServices: state.userServices.userServices
  }
}

export default connect(mapStateToProps) (UserServices);
