export const filterServices = categories => {
  let ser = [];
  categories.map(category => {
    ser.push({value: category.id, label: category.name });
    //category.services.map(service => {
    //})
  });
  let set = new Set(ser.map(JSON.stringify));
  return Array.from(set).map(JSON.parse);
}

export const filterUserServices = categories => {
  let ser = [];
  categories.map(category => {


    category.services.map(service => {
      ser.push({value: service.id, label: service.name });
    })
  });
  let set = new Set(ser.map(JSON.stringify));
  return Array.from(set).map(JSON.parse);
}