import React, {Component} from 'react';
import {Button, Modal, ModalFooter, ModalHeader} from "reactstrap";
import Footer from "../../../components/Footer/Footer";

class ResetUserModal extends Component {

  constructor() {
    super();
    this.state={
      user_id:"",
    }
  }
  onSubmit = e => {
    e.preventDefault();
    const {user_id} = this.state;
    this.props.onSubmit(user_id)
    this.props.toggle();
  }

  render() {
    const {isOpen, toggle, user_id } = this.props;
    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        onOpened={() => this.setState({user_id})}
      >
        <ModalHeader className={"text-center"}>
          ¿Estas Seguro de resetear los precios de este Usuario?
        </ModalHeader>
        <hr />
        <ModalFooter className={"text-center"}>
          <Button
            size={'sm'}
            color={'danger'}
            className={'text-left align-content-between'}
            onClick={toggle}
          >
            Cancelar
          </Button>
          <Button
            size={'sm'}
            color={'primary'}
            className={'text-right align-content-between'}
            onClick={(e) => this.onSubmit(e)}
          >
            Enviar
          </Button>
        </ModalFooter>
        <Footer />
      </Modal>
    );
  }
}

export default ResetUserModal;