const BillReducer = (state = {}, actions) => {
  switch (actions.type) {
    case "SET_BILLS":
      return {
        ...state,
        bills: actions.payload
      };
    case "SET_ADMIN_USER_BILLS":
        return {
          ...state,
          adminUserBill: actions.payload
        }
    case "SET_BILL":
      return {
        ...state,
        bills: actions.payload
      };

    default:
      return state;
  }
};
export default BillReducer;