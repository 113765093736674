import axios from "axios";
import cookie from "js-cookie";
export const api =  "https://api.como-liberar.com/api-brayan";
//export const api =  "https://api.imei-unlock.in/api-brayan";
 //export const api =  "http://127.0.0.1:8001/api-brayan";
//export const api =  "https://desblokeame.com/api-brayan";

// VSV$T/w1

export const timeOut = 4000;
//
// export const api = "https://new-brayan.fastunlock.site/api-brayan";
// export const api =  "http://127.0.0.1:8000/api-brayan";
//  export const api =  "https://api-test.desblokeame.com/api-brayan";
//export const api =  "https://api.desbloqueogratis.com/api-brayan";


export default class Base {
  instance = axios.create({
    baseURL: api,
    timeout: 0,
    headers: {
      Authorization: `Bearer ${cookie.get("token")}`,
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    }
  });
}
