import React, {Component} from 'react';
import PanelHeader from "../../../components/PanelHeader/PanelHeader";
import {Alert, Button, Card, CardBody, CardHeader, CardTitle, Col, Table} from "reactstrap";
import {tbody, thead, API_HEAD} from "../../../variables/general";
import CustomPagination from "../../../components/pagination/Pagination";
import {connect} from "react-redux";
import Loading from "../../../components/Loading";
import {timeOut} from "../../../functions/Base";
import ApiFunctions from "./functions/ApiFunctions";
import CreateApiModal from "./CreateApiModal";
import EditApiModal from "./EditApiModal";
import DeleteApiModal from "./DeleteApiModal";
import LinkFusionModal from "./LinkFusionModal";

class Apis extends Component {

  constructor(props) {
    super(props);
    this.state={
      isLoading: false,
      createModal: false,
      editModal: false,
      deleteModal: false,
      linkFusionModal: false,
      alert:null,
      colorAlert: "primary",
      visible: false,
      api_id: '',
      api:[]
    }
  }

  componentDidMount() {
    if (this.props.apis.data.length === 0) {
      this.setState({isLoading: true});
      new ApiFunctions().get(
        (data) => this.errorResponse(data),
        () => this.successResponse()
      )
    }
  }

  toggleCreateModal = () => this.setState({createModal: !this.state.createModal});

  toggleEditModal = api_id => this.setState({editModal: !this.state.editModal, api_id});

  toggleDeleteModal = api_id => this.setState({deleteModal: !this.state.deleteModal, api_id});

  toggleLinkFusionModal = api => this.setState({linkFusionModal: !this.state.linkFusionModal, api});

  successResponse = (message) => {
    this.setState({
      isLoading: false,
      alert: message,
      visible: true
    },  () => {
      setTimeout(() => {
        this.onDismiss()
      }, timeOut)
    });
  }

  changeIsLoading = () => this.setState({isLoading: !this.state.isLoading});

  errorResponse = (data) => {
    this.setState({
      isLoading: false,
      alert: data,
      colorAlert: "danger",
      visible: true,
    }, () => {
      setTimeout(() => {
        this.onDismiss()
      }, timeOut)
    });
  }

  sendRequest = ( data) => {
    this.setState({isLoading: true})
    switch (data.type){
      case "create":
        new ApiFunctions().store(data,
          (data) => this.successResponse(data),
          (data) => this.errorResponse(data),
        )
        break;
      case "edit":
        new ApiFunctions().update(data,
          (message, data) => this.successResponse(message, data),
          (data) => this.errorResponse(data),
        )
        break;
      case "delete":
        new ApiFunctions().delete(data,
          (data) => this.successResponse(data),
          (data) => this.errorResponse(data),
        )
        break;
      default:
        break;
    }
  }

  onDismiss = () => {this.setState({visible: !this.state.visible, colorAlert: 'primary'})}

  render() {
    const {apis} = this.props;
    const {isLoading, alert, colorAlert, api} = this.state;
    return (
      <>
        <PanelHeader size="sm" />
        <div className="content ">
          <Card className={'pt-3'}>
            <Col xs={12}>
              <Card>
                {alert && <Alert color={colorAlert} isOpen={this.state.visible} toggle={() => this.onDismiss}>
                  {alert}
                </Alert>}
                <CardHeader>
                  <CardTitle tag="h4">
                    <div className={"justify-content-between align-content-between text-center"}>
                      Apis <Button onClick={this.toggleCreateModal} className={"text-right btn btn-primary btn-round"}>Crear API</Button>
                    </div>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                    <tr>
                      {API_HEAD.map((prop, key) => {
                        if (key === API_HEAD.length - 1)
                          return (
                            <th key={key} className="">
                              {prop}
                            </th>
                          );
                        return <th key={key}>{prop}</th>;
                      })}
                      <th  className="text-right">
                        {'Acciones'}
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    {apis.data.map((prop, key) => {
                      return (
                        <tr key={prop.id}>
                          <td>{prop.name}</td>
                          <td>{prop.url}</td>
                          <td>{prop.api_key}</td>
                          <td>{prop.username}</td>
                          <td className="text-right">
                            <Button
                              className="btn-icon"
                              color="warning"
                              id="tooltip26024663"
                              size="sm"
                              type="button"
                              onClick={() => this.toggleLinkFusionModal(prop)}
                            >
                              <i className="now-ui-icons design_vector" />
                            </Button>
                            <Button
                              className="btn-icon ml-3"
                              color="info"
                              id="tooltip26024663"
                              size="sm"
                              type="button"
                              onClick={() => this.toggleEditModal(prop.id)}
                            >
                              <i className="now-ui-icons ui-2_settings-90" />
                            </Button>
                            <Button
                              className="btn-icon ml-2"
                              color="danger"
                              id="tooltip930083782"
                              size="sm"
                              type="button"
                              onClick={() => this.toggleDeleteModal(prop.id)}
                            >
                              <i className="now-ui-icons ui-1_simple-remove" />
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                    </tbody>
                    <tfoot>
                    <CustomPagination type={"SET_APIS"} change={this.changeIsLoading} links={apis.links} />
                    </tfoot>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Card>
          <Loading isLoading={isLoading}  />
          <CreateApiModal
            isOpen={this.state.createModal}
            toggle={() => this.toggleCreateModal()}
            onSubmit={( data) => this.sendRequest( data)}
          />
          <EditApiModal
            isOpen={this.state.editModal}
            toggle={(data) => this.toggleEditModal(data)}
            onSubmit={( data) => this.sendRequest( data)}
            api_id={this.state.api_id}
          />
          <DeleteApiModal
            isOpen={this.state.deleteModal}
            toggle={(data) => this.toggleDeleteModal(data)}
            onSubmit={( data) => this.sendRequest( data)}
            api_id={this.state.api_id}
          />
          <LinkFusionModal
            isOpen={this.state.linkFusionModal}
            toggle={(data) => this.toggleLinkFusionModal(data)}
            api={api}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    apis: state.apis.apis
  }
}

export default connect(mapStateToProps)(Apis);