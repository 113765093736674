import React, {Component} from 'react';
import {Button, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

class ShowServiceModal extends Component {
  constructor() {
    super();
    this.state={
      imeis: '',
      fields:[],
      qnt: ""
    }
  }

  onChange = e => this.setState({[e.target.name]: e.target.value}, () => {
    console.log(this.state)
  });

  open = () => {
    console.log(this.props);

    let {custom_field} = this.props.service
    let fields = [];
    if (custom_field) {
      let buff = new Buffer(custom_field, 'base64');
      let base64ToString = buff.toString('ascii');
      let myArray = base64ToString.split(',');

      myArray.map(newField => {
        this.setState({[newField]:""})
      })
      this.setState({fields: myArray})
    }

  }

  onSubmit = (e) => {
    console.log(this.state)
    e.preventDefault();
    let al = false;
    const {imeis, qnt} = this.state;
    let custom_field = '';
    if (this.state.fields.length > 0)  {
      let count = this.state.fields.length;
      this.state.fields.map((field, index) => {
        if (this.state[field] === "") {
          al = true
          return alert(`El Campo ${field} no puede ir vacio`);
        }else if (count === 1) {
          return custom_field = `{"${field}":"${this.state[field]}"}`
        }else if (index === 0) {
          return custom_field = `{"${field}":"${this.state[field]}"`
        }else if (index === count - 1) {
          return custom_field = `${custom_field},"${field}":"${this.state[field]}"}`
        } else {
          return custom_field = `${custom_field},"${field}":"${this.state[field]}"`
        }
      })
    }
    if (custom_field !== "") {
      custom_field = Buffer.from(custom_field).toString('base64');
    }
    let data = {
      imeis, custom_field
    }
    if (this.props.service.qnt === "true") {
      data = {
        qnt, custom_field
      }
      console.log(qnt)
      if (qnt > this.props.service.range_max || qnt < this.props.service.range_min) {
        al = true
        return alert(`La cantidad no puede ser menor de ${this.props.service.range_min} ni mayor que ${this.props.service.range_max}`)
      }
    }
    if (al === true) {
      this.props.toggle();
      return;
    } else {
      this.props.onSubmit(data)
      this.props.toggle();
    }
  }

  render() {
    const {isOpen, toggle, service} = this.props
    const {imei, qnt} = this.state
    return (
      <Modal
        onOpened={this.open}
        isOpen={isOpen}
        toggle={toggle}
        onClosed={() => this.setState({imeis:'', qnt:"", fields:[]})}
      >
        <Form onSubmit={() => this.onSubmit()}>
          <ModalHeader>

            {service && service.name}
          </ModalHeader>
          <ModalBody>
            Descripcion:
            <br />
            {
              service && service.description
            }
            <hr />
            <br />
            {
              service && service.time && service.owner && `${service.time} - $${service.owner[0].pivot.price}`
            }
            <hr />
            {
              this.props.service && this.props.service.qnt === "true" &&
                <>
                  <Label>Cantidad: </Label>
                  <Input
                    onChange={this.onChange}
                    name={'qnt'}
                    value={qnt}
                    type={'number'}
                    min={this.props.service.range_min}
                    max={this.props.service.range_max}
                    placeholder={`Minimo: ${this.props.service.range_min} - Maximo: ${this.props.service.range_max}`}
                  />
                </>

            }
            {
              this.props.service && this.props.service.imei === "true" &&
              <>
                <Label>Imeis: </Label>
                <Input
                  onChange={this.onChange}
                  name={'imeis'}
                  value={imei}
                  type={'textarea'}
                  placeholder={'Imei...'}
                />
              </>
            }

            {
              this.state.fields.map((field, index) => {
                //
                return (
                  <>
                    <Label>{field}</Label>
                    <Input
                      placeholder={field}
                      onChange={(e) => this.setState({[field]: e.target.value })}
                    />
                  </>
                )
              })
            }
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={toggle}
              size={"sm"}
              color={"danger"}
            >
              Cerrar
            </Button>
            <Button
              size={"sm"}
              color={"primary"}
              onClick={(e) => this.onSubmit(e)}
              type={'submit'}
            >
              Ordenar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
}

export default ShowServiceModal;