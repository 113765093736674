import React, {Component} from 'react';
import {
  Button,
  Card, Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import Loading from "../../../components/Loading";
import ApiFunctions from "./functions/ApiFunctions";

class EditApiModal extends Component {
  constructor() {
    super();
    this.state={
      name:'',
      url:'',
      api_key:'',
      username:'',
      isLoading: false,
    }
  }

  getApi = () => {
    this.setState({isLoading: true});
    new ApiFunctions().show(
      this.props.api_id,
      data => this.successResponse(data),
      data => this.errorResponse(data),
    )
  }


  successResponse = ({data}) => {
    this.setState({
      name: data.name,
      url: data.url,
      api_key: data.api_key,
      username: data.username,
      isLoading: false
    });
  }

  errorResponse = (data) => this.setState({isLoading: false});

  onChange = (e) => this.setState({[e.target.name]: e.target.value});

  onSubmit = (e) => {
    e.preventDefault();
    const {name, url, api_key, username} = this.state;
    const data = {
      id: this.props.api_id,
      url,
      api_key,
      username,
      name,
      type: "edit",
    }
    this.props.onSubmit(data);
    this.props.toggle()
  }
  render() {
    const {isOpen, toggle} = this.props;
    const {name, url, api_key, username} = this.state;
    return (
      <>
        <Modal
          onOpened={() => this.getApi()}
          isOpen={isOpen}
          toggle={toggle}
          onClosed={() => this.setState({name: '', url: '', api_key:'', username:''})}
          modalClassName={"modal-primary "}
        >
          <Card className="card-plain card-login">
            <ModalHeader className="justify-content-center" toggle={toggle} tag="div">
              <div className="header text-center ">
                <h2 >
                  {`Editar Api ${name}`}
                </h2>
              </div>
            </ModalHeader>
            <ModalBody className="card-content">
              <form className="form" onSubmit={e => this.onSubmit(e)}>
                <Col >
                  <Label className={"text-white"}>Nombre</Label>
                  <Input
                    type="text"
                    placeholder="Nombre de la Api"
                    name={'name'}
                    value={name}
                    onChange={this.onChange}
                    autoComplete={'off'}
                  />
                </Col>
                <Col >
                  <Label className={"text-white"}>URL</Label>
                  <Input
                    type="text"
                    placeholder="Url de la api"
                    name={'url'}
                    value={url}
                    onChange={this.onChange}
                    autoComplete={'off'}
                  />
                </Col>
                <Col >
                  <Label className={"text-white"}>ApiKey</Label>
                  <Input
                    type="text"
                    placeholder="Api Key de la api"
                    name={'api_key'}
                    value={api_key}
                    onChange={this.onChange}
                    autoComplete={'off'}
                  />
                </Col>
                <Col >
                  <Label className={"text-white"}>Username</Label>
                  <Input
                    type="text"
                    placeholder="Username de la api"
                    name={'username'}
                    value={username}
                    onChange={this.onChange}
                    autoComplete={'off'}
                  />
                </Col>
              </form>
            </ModalBody>
            <ModalFooter>
              <Button className={"btn-round"} color="secondary" onClick={toggle}>
                Cerrar
              </Button>
              <Button
                disabled={!name || !url || !api_key || !username}
                className={"btn btn-dark btn-round"}
                onClick={e => this.onSubmit(e)}
                type={'submit'}
              >
                Guardar Cambios
              </Button>
            </ModalFooter>
            <Loading isLoading={this.state.isLoading} />
          </Card>
        </Modal>
      </>
    );
  }
}

export default EditApiModal;