import React, {Component} from 'react';
import {
  Button,
  Card, Col, FormGroup,
  Input, InputGroupAddon, InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import Loading from "../../../components/Loading";
import Switch from "react-bootstrap-switch";
import Select from "react-select";
import ServicesFunctions from "./functions/ServicesFunctions";
import DatabaseFunctions from "../databases/functions/DatabaseFunctions";

class EditServiceModal extends Component {


  constructor() {
    super();
    this.state={
      name:'',
      description:'',
      time:'',
      cost:'',
      price:'',
      active: 'false',
      isLoading: false,
      categories: [],
      categoriesId: [],
      custom:[],
      range_max: "",
      range_min: "",
      qnt:"",
      databases:[],
      fields:[],
      field:"",
      imei: ""
    }
  }

  getService = () => {
    this.setState({isLoading: true});
    new ServicesFunctions().show(
      this.props.service_id,
      data => this.successResponse(data),
      data => this.errorResponse(data),
    )
    this.getCategories();
    this.getDatabases();
  }

  getCategories = () => new ServicesFunctions().getCategories(data => this.successCategories(data))

  getDatabases = () => new DatabaseFunctions().get(
    () => this.errorResponse(),
    () => this.successDatabases()
  )

  successCategories = (data) => {
    let categories = [];
    data.data.map(category => {
      return categories.push({
        value: category.id,
        label: category.name,
      })
    })
    this.setState({categories});
  }

  successDatabases = () => {
    let data = this.props.databases;
    let databases = [{
      value: null,
      label: "Sin Asignar"
    }];
    data.data.map(database => {
      return databases.push({
        value: database.id,
        label: database.database_name,
      })
    })
    this.setState({databases});
  }

  successResponse = ({data}) => {
    let active = '';
    let qnt = '';
    let imei = '';
    if (data.active === "false" ){
      active = false;
    } else {
      active = true;
    }
    if (data.imei === "false" ){
      imei = false;
    } else {
      imei = true;
    }

    if (data.qnt === "false" ){
      qnt = false;
    } else {
      qnt = true;
    }
    let categories = [];
    data.categories.map(category => {
      return categories.push({
        value: category.id,
        label: category.name,
      })
    })
    let custom = [];
    if (data.custom_data) {
      custom = {
        label: data.custom_data.database_name,
        value: data.custom_data.id,
      }
    }
    const {name, type, time, price, range_max, range_min, cost, description, custom_field} = data
    let fields = [];
    if (custom_field) {
      let buff = new Buffer(custom_field, 'base64');
      let base64ToString = buff.toString('ascii');
      let myArray = base64ToString.split(',');

      myArray.map(newField => {
        fields = [
          ...fields,
          {field: newField}
        ]
      })
    }
    this.setState({
      categoriesId: categories,
      fields,
      name,
      description,
      time,
      serviceType: type,
      price,
      cost,
      active,
      isLoading: false,
      qnt,
      range_max,
      range_min,
      custom,
      imei
    });
  }

  errorResponse = (data) => {
    this.setState({isLoading: false});
  }

  onChange = (e) => {
    this.setState({[e.target.name]: e.target.value});
  }

  onChangeSelect = (data) => this.setState({categoriesId: data})
  onChangeCustom = (data) => this.setState({custom: data})

  onChangeActive = (e) => this.setState({active: e.state.value});

  onChangeQnt = (e) => this.setState({qnt: e.state.value});

  onChangeImei = (e) => this.setState({imei: e.state.value});

  onSubmit = (e) => {
    e.preventDefault();
    const {fields} = this.state
    let fi = '';
    fields.map((prop, index) => {
      if (index  !== 0) return fi = `${fi},${prop.field}`
      return fi = prop.field;

    })
    const encodedString = Buffer.from(fi).toString('base64');
    const {name, serviceType, imei, active, description, time, cost, price, categoriesId, custom, qnt, range_max, range_min} = this.state;
    const data = {
      id: this.props.service_id,
      description,
      serviceType,
      time,
      cost,
      price,
      name,
      active: active.toString(),
      type: "edit",
      categories: categoriesId,
      custom,
      qnt: qnt.toString(),
      imei: imei.toString(),
      range_max,
      range_min,
      custom_field: encodedString
    }
    this.props.onSubmit(data);
    this.props.toggle();
  }

  newField = () => {
    this.setState({fields: this.state.fields.concat([{field: ""}])})
  }

  removeField = (i) => () => {
    this.setState({fields: this.state.fields.filter((field, index) => i !== index)})
  }

  readField = (i) => (e) =>  {
    const newFields = this.state.fields.map((field, index) => {
      if (i !== index) return field;
      return {
        ...field,
        field: e.target.value
      }
    });
    this.setState({fields: newFields});
  }

  render() {
    const {isOpen, toggle} = this.props;
    const {name,
      custom,
      databases,
      qnt,
      range_min,
      range_max,
      active,
      categoriesId,
      categories,
      description,
      time,
      cost,
      imei,
      price} = this.state;

    return (
      <>
        <Modal
          size={"lg"}
          onOpened={() => this.getService()}
          isOpen={isOpen}
          toggle={toggle}
          onClosed={() => this.setState({name: '', active: false, servicesId:[], services:[], custom:[]})}
          modalClassName={"modal-primary "}
        >
          <Card className="card-plain card-login">
            <ModalHeader className="justify-content-center" toggle={toggle} tag="div">
              <div className="header text-center ">
                <h2 >
                  {`Editar Servicio ${name}`}
                </h2>
              </div>
            </ModalHeader>
            <ModalBody className="card-content">
              <form className="form" onSubmit={e => this.onSubmit(e)}>
                <Col >
                  <Label className={"text-white"}>Nombre</Label>
                  <Input
                    type="text"
                    placeholder="Nombre de la Categoria"
                    name={'name'}
                    value={name}
                    onChange={this.onChange}
                    autoComplete={'off'}
                  />
                </Col>
                <Col >
                  <Label className={"text-white"}>Descripcion</Label>
                  <Input
                    type="textarea"
                    placeholder="Descripcion del servicio (opcional)"
                    name={'description'}
                    value={description}
                    onChange={this.onChange}
                    autoComplete={'off'}
                  />
                </Col>
                <Col >
                  <Label className={"text-white"}>Tiempo</Label>
                  <Input
                    type="text"
                    placeholder="Tiempo de respuesta (opcional)"
                    name={'time'}
                    value={time}
                    onChange={this.onChange}
                    autoComplete={'off'}
                  />
                </Col>
                <Col >
                  <Label className={"text-white"}>Costo</Label>
                  <Input
                    type="text"
                    placeholder="Costo del servicio"
                    name={'cost'}
                    value={cost}
                    onChange={this.onChange}
                    autoComplete={'off'}
                  />
                </Col>
                <Col >
                  <Label className={"text-white"}>Precio</Label>
                  <Input
                    type="text"
                    placeholder="Precio del servicio"
                    name={'price'}
                    value={price}
                    onChange={this.onChange}
                    autoComplete={'off'}
                  />
                </Col>
                <Col className={"mt-2 col-md-4"}>
                  <Label className={"text-white"}> Activo</Label><br />
                  <Switch
                    defaultValue={active}
                    name={'active'}
                    onChange={this.onChangeActive}
                    value={active}
                  />
                </Col>
                <Col className={"mt-2 col-md-4"}>
                  <Label className={"text-white"}> Tipo de Servicio</Label><br />
                  <FormGroup check>
                    <Label check>
                      <Input
                        aria-checked={this.state.serviceType === "IMEI"}
                        type="radio"
                        name="radio1"
                        checked={this.state.serviceType === "IMEI"}
                        onClick={() => this.setState({serviceType: "IMEI"})}
                      />{' '}
                      IMEI
                    </Label>
                    <Label check>
                      <Input
                        onClick={() => this.setState({serviceType: "SERVER"})}
                        aria-checked={this.state.serviceType === "SERVER"}
                        type="radio"
                        name="radio1"
                        checked={this.state.serviceType === "SERVER"}
                      />{' '}
                     Server
                    </Label>
                  </FormGroup>
                </Col>
                <Col className={"mt-2 col-md-4"}>
                  <Label className={"text-white"}> Imei</Label><br />
                  <Switch
                    defaultValue={imei}
                    name={'imei'}
                    onChange={this.onChangeImei}
                    value={imei}
                  />
                </Col>
                <Col className={"mt-2 col-md-4"}>
                  <Label className={"text-white"}> Qnt</Label><br />
                  <Switch
                    defaultValue={qnt}
                    name={'qnt'}
                    onChange={this.onChangeQnt}
                    value={qnt}
                  />
                </Col>

                {
                  qnt &&
                    <>
                      <Col >
                        <Label className={"text-white"}>Rango Minimo</Label>
                        <Input
                          type="text"
                          placeholder="Rango de la cantidad"
                          name={'range_min'}
                          value={range_min}
                          onChange={this.onChange}
                          autoComplete={'off'}
                        />
                      </Col>
                      <Col >
                        <Label className={"text-white"}>Rango Maximo</Label>
                        <Input
                          type="text"
                          placeholder="Rango de la cantidad"
                          name={'range_max'}
                          value={range_max}
                          onChange={this.onChange}
                          autoComplete={'off'}
                        />
                      </Col>
                    </>
                }
                <Col className={"text-center pt-2"}>
                  <Button
                    type={"button"}
                    onClick={this.newField}
                  >
                    + Agregar Campo Custom
                  </Button>
                  {
                    this.state.fields.map((input, index) => (
                      <Col key={`custom-field-${index}`}>
                        <Label className={"text-white"}>Campo Custom {index + 1}</Label>
                        <div className={"input-group"}>
                          <Input
                            size={"sm"}
                            type="text"
                            className={"form-control"}
                            placeholder="Nombre del campo"
                            name={'field'}
                            value={this.state.fields[index].field}
                            onChange={this.readField(index)}
                            //autoComplete={'off'}
                          />
                          <InputGroupAddon addonType="append">
                            <InputGroupText
                              onClick={this.removeField(index)}
                            >
                              &times; Eliminar
                            </InputGroupText>
                          </InputGroupAddon>
                        </div>

                      </Col>
                    ))
                  }
                </Col>
                <Col>
                  <Label>Categorias</Label>
                  <Select
                    className="react-select warning"
                    classNamePrefix="react-select"
                    isMulti
                    closeMenuOnSelect={false}
                    placeholder="Multiple Select"
                    name="multipleSelect"
                    value={categoriesId}
                    options={categories}
                    onChange={(value) => this.onChangeSelect(value)}
                  />
                </Col>
                <Col>
                  <Label>Base de Datos</Label>
                  <Select
                    className="react-select warning"
                    classNamePrefix="react-select"
                    closeMenuOnSelect={true}
                    placeholder="Base de Datos"
                    name="custom"
                    value={custom}
                    options={databases}
                    onChange={(value) => this.onChangeCustom(value)}
                  />
                </Col>
              </form>
            </ModalBody>
            <ModalFooter>
              <Button className={"btn-round"} color="secondary" onClick={toggle}>
                Cerrar
              </Button>
              <Button
                disabled={!this.state.name}
                className={"btn btn-dark btn-round"}
                onClick={e => this.onSubmit(e)}
                type={'submit'}
              >
                Guardar Cambios
              </Button>
            </ModalFooter>
            <Loading isLoading={this.state.isLoading} />
          </Card>
        </Modal>
      </>
    );
  }
}

export default EditServiceModal;