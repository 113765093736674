import React, {Component} from 'react';
import PanelHeader from "../../../components/PanelHeader/PanelHeader";
import {Alert, Button, Card, CardBody, CardHeader, CardTitle, Col, Table} from "reactstrap";
import {tbody, thead, USER_HEAD} from "../../../variables/general";
import CustomPagination from "../../../components/pagination/Pagination";
import {connect} from "react-redux";
import Loading from "../../../components/Loading";
import {timeOut} from "../../../functions/Base";
import UserFunctions from "./functions/UserFunctions";
import CreateUserModal from "./CreateUserModal";
import DeleteUserModal from "./DeleteUserModal";
import ActiveModal from "./ActiveModal";
import ResetUserModal from "./ResetUserModal";

class User extends Component {

  constructor(props) {
    super(props);
    this.state={
      isLoading: false,
      createModal: false,
      editModal: false,
      deleteModal: false,
      activeModal: false,
      alert:null,
      colorAlert: "primary",
      visible: false,
      user_id: '',
      user: [],
      resetModal: false
    }
  }

  componentDidMount() {
    if (this.props.users.data.length === 0) {
      this.setState({isLoading: true});
      new UserFunctions().get(
        (data) => this.errorResponse(data),
        () => this.successResponse()
      )
    }
  }

  toggleCreateModal = () => this.setState({createModal: !this.state.createModal});

  toggleEditModal = user_id => this.setState({editModal: !this.state.editModal, user_id});

  toggleDeleteModal = user_id => this.setState({deleteModal: !this.state.deleteModal, user_id});

  toggleActiveModal = user => this.setState({ activeModal: !this.state.activeModal, user});

  successResponse = (message) => {
    this.setState({
      isLoading: false,
      alert: message,
      visible: true
    },  () => {
      setTimeout(() => {
        this.onDismiss()
      }, timeOut)
    });

  }

  changeIsLoading = () => this.setState({isLoading: !this.state.isLoading});

  errorResponse = (data) => {
    console.log(data);
    this.setState({
      isLoading: false,
      alert: data,
      colorAlert: "danger",
      visible: true,

    }, () => {
      setTimeout(() => {
        this.onDismiss()
      }, timeOut)
    });
  }

  sendUserRequest = (data) => {
    this.setState({isLoading: true})
    switch (data.type){
      case "create":
        new UserFunctions().store(data,
          (data) => this.successResponse(data),
          (data) => this.errorResponse(data),
        )
        break;
      case "edit":
        new UserFunctions().update(data,
          (message, data) => this.successResponse(message, data),
          (data) => this.errorResponse(data),
        )
        break;
      case "delete":
        new UserFunctions().delete(data,
          (data) => this.successResponse(data),
          (data) => this.errorResponse(data),
        )
        break;
      default:
        break;
    }
  }

  onDismiss = () => {
    this.setState({visible: !this.state.visible, colorAlert: 'primary'})
  }

  toggleResetModal = user_id => this.setState({resetModal: !this.state.resetModal, user_id});

  onResetUsers = id => {
    this.setState({isLoading: true});
    new UserFunctions().resetUser(
      id,
      data => this.successResponse(data),
      data => this.errorResponse(data)
    )
  }

  render() {
    const {users} = this.props;
    const {isLoading, alert, colorAlert} = this.state;
    return (
      <>
        <PanelHeader size="sm" />
        <div className="content ">
          <Card className={'pt-3'}>
            <Col xs={12}>
              <Card>
                {alert && <Alert color={colorAlert} isOpen={this.state.visible} toggle={() => this.onDismiss}>
                  {alert}
                </Alert>}
                <CardHeader>
                  <CardTitle tag="h4">
                    <div className={"justify-content-between align-content-between text-center"}>
                      Usuarios <Button onClick={this.toggleCreateModal} className={"text-right btn btn-primary btn-round"}>Crear usuario</Button>
                    </div>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                    <tr>
                      {USER_HEAD.map((prop, key) => {
                        if (key === USER_HEAD.length - 1)
                          return (
                            <th key={key} className="">
                              {prop}
                            </th>
                          );
                        return <th key={key}>{prop}</th>;
                      })}
                      <th  className="text-right">
                        {'Acciones'}
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    {users.data.map((prop, key) => {
                      return (
                        <tr key={prop.id}>
                          <td
                            onClick={() => this.props.history.push(`admin-user/${prop.id}`, prop)}
                          >
                            {prop.name}
                          </td>
                          <td
                            onClick={() => this.props.history.push(`admin-user/${prop.id}`, prop)}
                          >
                            {prop.email}
                          </td>
                          <td
                            onClick={() => this.props.history.push(`admin-user/${prop.id}`, prop)}
                          >
                            ${prop.credits}
                          </td>
                          <td>
                            <Button
                              size={'sm'}
                              className="rounded"
                              color={prop.active === "true" ? 'success' : 'danger'}
                              onClick={() => this.toggleActiveModal(prop)}
                            >
                              {prop.active === "true" ? "Activo" : "Inactivo"}
                            </Button>
                          </td>
                          <td className="text-right">
                            <Button
                              className="btn-icon mr-2"
                              color="info"
                              id="tooltip26024663"
                              size="sm"
                              type="button"
                              onClick={() => this.toggleResetModal(prop.id)}
                            >
                              <i className="now-ui-icons arrows-1_refresh-69" />
                            </Button>
                            <Button
                              className="btn-icon"
                              color="info"
                              id="tooltip26024663"
                              size="sm"
                              type="button"
                              onClick={() => this.props.history.push(`admin-user/${prop.id}`, prop)}
                            >
                              <i className="now-ui-icons ui-2_settings-90" />
                            </Button>
                            {
                              /**
                               *  <Button
                               className="btn-icon ml-2"
                               color="danger"
                               id="tooltip930083782"
                               size="sm"
                               type="button"
                               onClick={() => this.toggleDeleteModal(prop.id)}
                               >
                               <i className="now-ui-icons ui-1_simple-remove" />
                               </Button>
                               */
                            }
                          </td>
                        </tr>
                      );
                    })}
                    </tbody>
                    <tfoot>
                    <CustomPagination type={"SET_USERS"} change={this.changeIsLoading} links={users.links} />
                    </tfoot>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Card>
          <Loading isLoading={isLoading}  />
          <CreateUserModal
            isOpen={this.state.createModal}
            toggle={() => this.toggleCreateModal()}
            onSubmit={( data) => this.sendUserRequest( data)}
          />
          <ActiveModal
            isOpen={this.state.activeModal}
            toggle={ (data) => this.toggleActiveModal(data)}
            user={this.state.user}
            onsubmit={data => this.sendUserRequest(data)}
          />
          <DeleteUserModal
            isOpen={this.state.deleteModal}
            toggle={(data) => this.toggleDeleteModal(data)}
            onSubmit={( data) => this.sendUserRequest( data)}
            user_id={this.state.user_id}
          />
          <ResetUserModal
            isOpen={this.state.resetModal}
            toggle={(data) => this.toggleResetModal(data)}
            user_id={this.state.user_id}
            onSubmit={(data) => this.onResetUsers(data)}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    users: state.users.users
  }
}

export default connect(mapStateToProps)(User);