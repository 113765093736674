import React, {Component} from 'react';
import {Button, Col, Modal, ModalBody, ModalHeader,Table, Row} from "reactstrap";
import Loading from "../../../components/Loading";
import ApiFunctions from "./functions/ApiFunctions";


class LinkFusionModal extends Component {
  constructor() {
    super();
    this.state = {
      services:[],
      isLoading: false,
      error: "",
    }
  }

  sinc = () => {
    this.setState({isLoading:true});
    new ApiFunctions().sync(
      this.props.api.id,
        data => this.successResponse(data),
        data => this.errorResponse(data)
    )
  }

  successResponse = (data) => this.setState({services:data.data, isLoading: false});

  errorResponse = (data) => {
    this.setState({isLoading: false, error: data});
    console.log(data);
  }

  render() {
    const {isOpen, toggle} = this.props;
    const {isLoading, services} = this.state;
    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        size={'lg'}
        onClosed={() => this.setState({})}
        modalClassName={"modal-primary "}
      >
        <ModalHeader className="justify-content-center" toggle={toggle} tag="div">
          <div className="header text-center ">
            <h2 >
              Servicios de la api
            </h2>
          </div>
        </ModalHeader>
        <ModalBody className={'text-center'}>
          <Button
            className={'text-center'}
            onClick={() => this.sinc()}
          >
            Sincronizar
          </Button>
          <code className={"text-danger"}>
            <pre className={"text-danger"}>
              {this.state.error}
            </pre>
          </code>
          <Row>
            <Col>
              { services.length > 0 &&
                <Table responsive className={"text-white"}>
                  <thead className="text-white">
                  <tr>
                    <td>Service ID</td>
                    <td>Nombre</td>
                    <td>Tiempo</td>
                    <td>Costo</td>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    services.map(service => {
                      return (
                        <tr>
                          <td >{service.service_id}</td>
                          <td>{service.name}</td>
                          <td>{service.time}</td>
                          <td>{service.cost}</td>
                        </tr>

                        )
                  })
                  }

                  </tbody>
                </Table>
              }

            </Col>
          </Row>
        </ModalBody>
        <Loading isLoading={isLoading}/>
      </Modal>
    );
  }
}

export default LinkFusionModal;
