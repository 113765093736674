
import React from "react";
// react plugin used to create charts
import { Line } from "react-chartjs-2";
// react plugin for creating vector maps

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col, Badge, Input, CardFooter, Button,
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";

import DashboardFunctions from "./functions/DashboardFunctions";
import ReactHtmlParser from "react-html-parser";
import Loading from "../../components/Loading";
import OrderFunctions from "../admin/orders/functions/OrderFunctions";
import {connect} from "react-redux";
import {filterServices} from "../../functions/filterServices";
import CustomPagination from "../../components/pagination/Pagination";
import moment from "moment";
import RejectOrderModal from "../admin/orders/RejectOrderModal";
import SuccessOrderModal from "../admin/orders/SuccessOrderModal";
import UserServiceFunctions from "../users/services/functions/UserServiceFunctions";
import {dashboardChart} from "../../functions/dashboardChart";

class AdminDashboard extends React.Component {
  constructor() {
    super();
    this.state={
      credits:'',
      orders:[],
      graph:[],
      processCredits: '',
      processOrders: '',
      purchasedCredits: '',
      rejectedOrders: '',
      successOrders: '',
      totalCredits: '',
      totalOrders: '',
      isLoading: false,
      usersRegisteredLastWeek: '',
      ordersInDay:'',
      id:'',
      status: '',
      service_id:'',
      services:[],
      imei:"",
      profitDay: '',
      profitWeek: '',
      rejectOrderModal: false,
      order:[],
      successOrderModal: false,
    }
  }

  async componentDidMount () {
    this.setState({isLoading: true});
    const {categories} = this.props
    const services = filterServices(categories);

    await new UserServiceFunctions().get();
    this.setState({services})
    this.setState({isLoading: true});
    await this.getData();
    await this.onSubmit();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.categories.length > 0 && this.state.services.length === 0) {
      const services = filterServices(this.props.categories);
      this.setState({services})
    }
  }


  getData = () => {
    new DashboardFunctions().getAdmin(
      data => this.successRespons(data)
    )
  }

  successRespons = data => {
    const {
      processCredits,
      processOrders,
      purchasedCredits,
      rejectedOrders,
      successOrders,
      totalCredits,
      totalOrders,
      usersRegisteredLastWeek,
      graph,
      orders,
      successCredits,
      creditsPending,
      totalUsers,
      ordersInDay,
      profitDay,
      profitWeek
    } = data.data
    this.setState({
      processCredits,
      processOrders,
      purchasedCredits,
      rejectedOrders,
      successOrders,
      totalCredits,
      totalOrders,
      graph,
      orders,
      successCredits,
      creditsPending,
      totalUsers,
      usersRegisteredLastWeek,
      ordersInDay,
      isLoading: false,
      profitDay,
      profitWeek,
    })
  }

  badge = (status) => {
    let data = {color: 'info', status: "Pendiente"};
    switch (status){
      case "0":
        data = {color: 'info', status: "Pendiente"};
        break;
      case "1":
        data = {color: 'warning', status: "En Proceso"};
        break;
      case "3":
        data = {color: 'danger', status: "Rechazada"};
        break;
      case "4":
        data = {color: 'success', status: "Completada"};
        break;
      default:
        data = {color: 'info', status: "Pendiente"};
        break;
    }
    return data;
  }

  labels = () => {
    let label = [];
    this.state.graph.map((day) => {
      label.push(day.month);
    })
    return label;
  }

  dataChart = () => {
    let label = [];
    this.state.graph.map((day) => {
      label.push(day.count);
    })
    return label;
  }

  onChange = (e) => {
    this.setState({[e.target.name]: e.target.value}, () => {
      this.onSubmit();
    });
  }

  onSubmit = () => {
    const {imei, service_id, status, id} = this.state;
    const data = {imei, service_id, status, id};
    new OrderFunctions().get(
      data,
      (data) => this.errorResponse(data),
      () => this.successResponse()
    )
  }

  successResponse = () => this.setState({isLoading: false});

  errorResponse = () => this.setState({isLoading: false});

  changeIsLoading = () => {this.setState({isLoading: !this.state.isLoading})}

  onCompleteOrder = (data) => {
    this.setState({isLoading: true});
    if (data.response === "") {
      data.response = "Listo";
    }
    new OrderFunctions().responseOrder(
      data,
      data => this.success(data),
      data => this.error(data)
    )

  }

  onRejectOrder = (data) => {
    this.setState({isLoading: true});
    if (data.response === "") {
      data.response = "No Encontrado";
    }
    new OrderFunctions().responseOrder(
      data,
      data => this.success(data),
      data => this.error(data))
  }

  toggleReject = (order) => {
    if (!order) {
      order= []
    }
    this.setState({rejectOrderModal: !this.state.rejectOrderModal, order})
  }

  toggleSuccess = (order) => {
    if (!order) {
      order= []
    }
    this.setState({successOrderModal: !this.state.successOrderModal, order})
  }

  success = (data) => {
    this.onSubmit();
    this.setState({isLoading: false});
  }

  error = (data) => {
    this.setState({isLoading: false});
  }

  api = (order) => {
    let res = "";
    if (order.phase === "NO") {
      res = "";
    } else if (order.phase === "1" && order.service.api1) {
      res = order.service.api1.name;
    } else if (order.phase === "2" && order.service.api2) {
      res = order.service.api2.name;
    }
    return res;
  }

  customField = (order) => {
    let field = "";
    if (order.imei !== "" && order.imei) {
      field = order.imei;
    }
    if (order.custom_fields) {
      try {
        let buff = new Buffer(order.custom_fields, 'base64');
        let base64ToString = buff.toString('ascii');
        let myArray = base64ToString.split(',');

        let custom = Array(JSON.parse(myArray))
        custom.map((prop, index) => {
          if (prop) {
            field = `${field} <br /> ${Object.values(prop)}`
          }
        })
        field = field  && field.replace(",", "<br />")
        return field
      } catch (e) {
        field = order.imei;
        return field;
      }
    }
    return field;
  }

  render() {
    const {imei, service_id, status, id} = this.state;
    let dataSend = {imei, service_id, status, id};
    return (
      <>
        <PanelHeader
          size="lg"
          content={
            <Line
              data={dashboardChart(this.state.graph).data}
              options={dashboardChart(this.state.graph).options}
            />
          }
        />
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <Card className="card-stats card-raised">
                <CardBody >
                  <Row>
                    <Col md="3">
                      <div className="statistics ">
                        <div className="info">
                          <h3 className="info-title ">{this.state.successCredits && this.state.successCredits.toFixed(2)}</h3>
                          <h6 className="stats-title">Creditos Gastados</h6>
                        </div>
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="statistics ">
                        <div className="info">
                          <h3 className="info-title ">
                            {this.state.totalCredits && this.state.totalCredits.toFixed(2)}
                          </h3>
                          <h6 className="stats-title">Creditos Comprados</h6>
                        </div>
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="statistics ">
                        <div className="info">
                          <h3 className="info-title ">{this.state.creditsPending && this.state.creditsPending.toFixed(2)}</h3>
                          <h6 className="stats-title">Creditos Sin Gastar</h6>
                        </div>
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="statistics" >
                        <div className="info">
                          <h3 className="info-title ">{this.state.processCredits && this.state.processCredits.toFixed(2)}</h3>
                          <h6 className="stats-title">Creditos en Proceso</h6>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col md="3">
                      <div className="statistics ">
                        <div className="info">
                          <h3 className="info-title ">{this.state.successOrders}</h3>
                          <h6 className="stats-title">Ordenes Contestadas</h6>
                        </div>
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="statistics ">
                        <div className="info">
                          <h3 className="info-title">
                            {this.state.processOrders}
                          </h3>
                          <h6 className="stats-title">Ordenes En Proceso</h6>
                        </div>
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="statistics ">
                        <div className="info">
                          <h3 className="info-title">{this.state.rejectedOrders}</h3>
                          <h6 className="stats-title">Ordenes Rechazadas</h6>
                        </div>
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="statistics" >
                        <div className="info">
                          <h3 className="info-title">{this.state.totalOrders}</h3>
                          <h6 className="stats-title">Ordenes Totales</h6>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <hr />
                <CardHeader >
                  <Row>
                    <Col md="4">
                      <div className="statistics">
                        <div className="info">
                          <h3 className="info-title ">{this.state.totalUsers}</h3>
                          <h6 className="stats-title">Usuarios Registrados</h6>
                        </div>
                      </div>
                    </Col>
                    <Col md="4">
                      <div className="statistics">
                        <div className="info">
                          <h3 className="info-title">{this.state.usersRegisteredLastWeek}</h3>
                          <h6 className="stats-title">Usuarios Registrados Esta Semana</h6>
                        </div>
                      </div>
                    </Col>
                    <Col md="4">
                      <div className="statistics">
                        <div className="info">
                          <h3 className="info-title">{this.state.ordersInDay}</h3>
                          <h6 className="stats-title">Ordenes En el Dia</h6>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <hr />
                <CardHeader >
                  <Row>
                    <Col md="6">
                      <div className="statistics">
                        <div className="info">
                          <h3 className="info-title">{this.state.profitDay && this.state.profitDay.toFixed(2)}</h3>
                          <h6 className="stats-title">Ganancia Del Día</h6>
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="statistics">
                        <div className="info">
                          <h3 className="info-title">{this.state.profitWeek && this.state.profitWeek.toFixed(2)}</h3>
                          <h6 className="stats-title">Ganancia En la Semana</h6>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
              </Card>
            </Col>
          </Row>

          <Row>
             <Col xs={12} md={12}>
             <Card>
             <CardHeader>
             <CardTitle tag="h4" className={"text-center"}>
               200 Ultimas Ordenes{" "}
               <Button
                 size={"sm"}
                 color={"primary"}
                 onClick={() => this.componentDidMount()}
               >
                 Actualizar
               </Button>
             </CardTitle>
             </CardHeader>
             <CardBody>
              <Table responsive className="table-shopping">
               <thead>
                 <tr className="text-center">
                   <th className="text-center">
                     <Input
                       name={'id'}
                       placeholder={'ID...'}
                       value={this.state.id}
                       onChange={this.onChange}
                     />
                   </th>
                   <th>Fecha</th>
                   <th>
                     <Input
                       defaultValue={''}
                       onChange={this.onChange}
                       type="select"
                       name="status"
                       id="select">
                       <option  value={""}>ALL</option>
                       <option value={"1"}>En Proceso</option>
                       <option value={"3"}>Rechazado</option>
                       <option value={"4"}>Completada</option>
                     </Input>
                   </th>
                   <th>
                     <Input
                       onChange={this.onChange}
                       type="select"
                       name="service_id"
                       id="select">
                       <option selected value={""}>ALL</option>
                       {
                         this.state.services.map(service => {
                           return (
                             <option value={service.value}>{service.label}</option>
                           )
                         })
                       }
                     </Input>
                   </th>
                   <th>Api</th>
                   <th>Usuario</th>
                   <th>Costo</th>
                   <th>
                     <Input
                       name={'imei'}
                       placeholder={'Buscar por imei...'}
                       value={this.state.imei}
                       onChange={this.onChange}
                     />
                   </th>
                   <th className={'text-center'}>Respuesta</th>

                   <th>Rechazar</th>
                   <th>Contestar</th>
                 </tr>
               </thead>
               <tbody>
                {
                  this.props.orders.data.map(prop => {
                    const status = this.badge(prop.status);
                    const customField = this.customField(prop)
                    return (
                      <tr className="text-center" key={`table-dashboard-${prop.id}`}>
                        <td >{prop.id}
                          <br />
                          <small className={"text-danger"}>{prop.status === "1" && prop.error}</small></td>
                        <td>{moment(prop.created_at).format('YYYY-MM-D')}</td>
                        <td>
                          <Badge color={status.color}>
                            {status.status}
                          </Badge>
                        </td>
                        <td>{prop.service.name}</td>
                        <td>{this.api(prop)}</td>
                        <td>{prop.user.name}</td>
                        <td>${prop.price.toFixed(2)}</td>
                        <td>{ReactHtmlParser(customField)}</td>
                        <td className={'text-center'}>{ReactHtmlParser(prop.response)}</td>
                        <td className={"text-right"}>
                          <Button
                            color={'danger'}
                            size={'sm'}
                            onClick={() => this.toggleReject(prop)}
                            disabled={prop.status === "3"}
                          >
                            Rechazar
                          </Button>
                        </td>
                        <td className={"text-right"}>
                          <Button
                            size={'sm'}
                            color={'success'}
                            onClick={() => this.toggleSuccess(prop)}
                            disabled={prop.status === "4"}
                          >
                            Contestar
                          </Button>
                        </td>
                      </tr>
                    )
                  })
                }
             </tbody>
             </Table>
             </CardBody>
             <CardFooter>
               <CustomPagination extraData={dataSend} type={"SET_ORDERS_HISTORY"} change={this.changeIsLoading} links={this.props.orders.links} />
             </CardFooter>
             </Card>
           </Col>
          </Row>
          <RejectOrderModal
            isOpen={this.state.rejectOrderModal}
            toggle={(data) => this.toggleReject(data)}
            order={this.state.order}
            onRejectOrder={data => this.onRejectOrder(data)}
          />
          <SuccessOrderModal
            isOpen={this.state.successOrderModal}
            toggle={(data) => this.toggleSuccess(data)}
            order={this.state.order}
            onRejectOrder={data => this.onCompleteOrder(data)}
          />
          <Loading isLoading={this.state.isLoading} />
        </div>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    orders: state.orders.order_history,
    categories: state.userServices.userServices,
  }
}

export default connect(mapStateToProps) (AdminDashboard);
