import React, {Component} from 'react';
import {
  Button,
  Card, Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";

class CreateServiceModal extends Component {
  constructor() {
    super();
    this.state={
      name:'',
      description:'',
      time:'',
      cost:'',
      price:'',
    }
  }

  onChange = (e) => {
    this.setState({[e.target.name]: e.target.value});
  }

  onSubmit = (e) => {
    e.preventDefault();
    const {name, description, time, cost, price} = this.state;
    const data = {name,description, time, cost, price, type: "create"}
    this.props.onSubmit(data);
    this.props.toggle()
  }
  render() {
    const {isOpen, toggle} = this.props;
    const {name, cost, price} = this.state;
    return (
      <>
        <Modal
          isOpen={isOpen}
          toggle={toggle}
          onClosed={() => this.setState({name: ''})}
          modalClassName={"modal-primary "}
        >
          <Card className="card-plain card-login">
            <ModalHeader className="justify-content-center" toggle={toggle} tag="div">
              <div className="header text-center ">
                <h2 >
                  Crear Servicio
                </h2>
              </div>
            </ModalHeader>
            <ModalBody className="card-content">
              <form className="form" onSubmit={e => this.onSubmit(e)}>
                <Col >
                  <Label className={"text-white"}>Nombre</Label>
                  <Input
                    type="text"
                    placeholder="Nombre del Servicio"
                    name={'name'}
                    onChange={this.onChange}
                    autoComplete={'off'}
                  />
                </Col>
                <Col >
                  <Label className={"text-white"}>Descripcion</Label>
                  <Input
                    type="textarea"
                    placeholder="Descripcion del Servicio (opcional)"
                    name={'description'}
                    onChange={this.onChange}
                    autoComplete={'off'}
                  />
                </Col>
                <Col >
                  <Label className={"text-white"}>Tiempo de Respuesta</Label>
                  <Input
                    type="text"
                    placeholder="Tiempo de respuesta (opcional)"
                    name={'time'}
                    onChange={this.onChange}
                    autoComplete={'off'}
                  />
                </Col>
                <Col >
                  <Label className={"text-white"}>Costo</Label>
                  <Input
                    type="text"
                    placeholder="Costo"
                    name={'cost'}
                    onChange={this.onChange}
                    autoComplete={'off'}
                  />
                </Col>
                <Col >
                  <Label className={"text-white"}>Precio</Label>
                  <Input
                    type="text"
                    placeholder="Precio"
                    name={'price'}
                    onChange={this.onChange}
                    autoComplete={'off'}
                  />
                </Col>
              </form>
            </ModalBody>
            <ModalFooter>
              <Button className={"btn-round"} color="secondary" onClick={toggle}>
                Cerrar
              </Button>
              <Button
                disabled={!name || !price || !cost}
                className={"btn btn-dark btn-round"}
                onClick={e => this.onSubmit(e)}
                type={'submit'}
              >
                Guardar Cambios
              </Button>
            </ModalFooter>
          </Card>
        </Modal>
      </>

    );
  }
}

export default CreateServiceModal;