import Base from "../../../../functions/Base";
import store from "../../../../store";

export default class DatabaseFunctions extends Base {
  get(errorResponse, successResponse){
    this.instance.get('/custom-databases').then(res => {
      store.dispatch({
        type: "SET_DATABASES",
        payload: res.data.data
      });
      successResponse && successResponse()
    }).catch(err => {
      if (err.response && err.response.data) {
        errorResponse(err.response.data.error)
      } else {
        successResponse && errorResponse("Error inesperado, intentelo de nuevo");
      }
    });
  }

  show(id, successResponse, errorResponse){
    this.instance.get(`/custom-databases/${id}`).then(res => {
      successResponse(res.data)
    }).catch(err => {
      if (err.response && err.response.data) {
        errorResponse(err.response.data.error)
      } else {
        errorResponse("Error inesperado, intentelo de nuevo");
      }
    });
  }

  store (data, successResponse, errorResponse){
    this.instance.post('/custom-databases', data).then(({data}) => {
      console.log("res: database", data)
      store.dispatch({
        type: "SET_DATABASES",
        payload: data.data
      });
      successResponse("Base de datos creada correctamente.")
    }).catch(err => {
      console.log(err, "err: database");
      if (err.response && err.response.data) {
        errorResponse(err.response.data.error)
      } else {
        errorResponse("Error inesperado, intentelo de nuevo");
      }
    })
  }

  delete (data, successResponse, errorResponse){
    this.instance.delete(`custom-databases/${data.id}`)
      .then(({data}) => {
        store.dispatch({
          type: "DATABASE_DELETE",
          payload: data.data
        });
        successResponse("Categoria eliminada correctamente.");
      })
      .catch(({response}) => {
        if (response && response.data) {
          errorResponse(response.data.error)
        } else {
          errorResponse("Error inesperado, intentelo de nuevo");
        }
      })
  }
}