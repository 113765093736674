import Base from "../../../../functions/Base";
import store from "../../../../store";

export default class CategoryFunctions extends Base {
  get(errorResponse, successResponse){
    this.instance.get('/admin-categories').then(res => {
      store.dispatch({
        type: "SET_CATEGORIES",
        payload: res.data.data
      });
      successResponse()
    }).catch(err => {
      if (err.response && err.response.data) {
        errorResponse(err.response.data.error)
      } else {
        errorResponse("Error inesperado, intentelo de nuevo");
      }
    });
  }

  show(id, successResponse, errorResponse){
    this.instance.get(`/admin-categories/${id}`).then(res => {
      successResponse(res.data)
    }).catch(err => {
      if (err.response && err.response.data) {
        errorResponse(err.response.data.error)
      } else {
        errorResponse("Error inesperado, intentelo de nuevo");
      }
    });
  }

  store (data, successResponse, errorResponse){
    this.instance.post('/admin-categories', data).then(({data}) => {
      store.dispatch({
        type: "SET_CATEGORIES",
        payload: data.data
      });
      successResponse("Categoria creada correctamente.")
    }).catch(err => {
      if (err.response && err.response.data) {
        errorResponse(err.response.data.error)
      } else {
        errorResponse("Error inesperado, intentelo de nuevo");
      }
    })
  }

  update ( data, successResponse,  errorResponse){
    this.instance.patch(`/admin-categories/${data.id}`, data).then(({data}) => {
      store.dispatch({
        type: "CATEGORY_EDIT",
        payload: data.data
      });
      successResponse("Categoria editada correctamente.");
    }).catch(err => {
      if (err.response && err.response.data) {
        errorResponse(err.response.data.error)
      } else {
        errorResponse("Error inesperado, intentelo de nuevo");
      }
    })
  }

  delete (data, successResponse, errorResponse){
    this.instance.delete(`admin-categories/${data.id}`)
      .then(({data}) => {
        store.dispatch({
          type: "CATEGORY_DELETE",
          payload: data.data
        });
        successResponse("Categoria eliminada correctamente.");
      })
      .catch(({response}) => {
        if (response && response.data) {
          errorResponse(response.data.error)
        } else {
          errorResponse("Error inesperado, intentelo de nuevo");
        }
      })
  }

  getServices(successResponse,  errorResponse) {
    this.instance.get("admin-services-all")
      .then(({data}) => {
        successResponse(data);
      })
      .catch(({response}) => {
        console.log(response);
        errorResponse("Error al cargar los servicios");
      })
  }
}