import React, {Component} from 'react';
import {Modal, ModalBody, Spinner} from "reactstrap";

class Loading extends Component {
  render() {
    return (
      <Modal contentClassName="bg-transparent shadow-none"  centered isOpen={this.props.isLoading}>
        <ModalBody className="text-center">
          <Spinner type="grow" size={'xl'} className="text-center text-dark" style={{zIndex: 5000}} />
          <br />
          <h1 className="text-dark">
            Cargando...
          </h1>
        </ModalBody>
      </Modal>
    );
  }
}

export default Loading;