import React, {Component} from 'react';
import PanelHeader from "../../../components/PanelHeader/PanelHeader";
import {Alert, Badge, Button, Card, CardBody, CardHeader, CardTitle, Col, Input, Table} from "reactstrap";
import {tbody, thead} from "../../../variables/general";
import CustomPagination from "../../../components/pagination/Pagination";
import {connect} from "react-redux";
import Loading from "../../../components/Loading";
import {timeOut} from "../../../functions/Base";
import moment from "moment";
import 'moment/locale/es';
import OrderFunctions from "./functions/OrderFunctions";
import {filterServices} from "../../../functions/filterServices";
import ReactHtmlParser from 'react-html-parser';
import {badge} from "../../../functions/statusOrder";

class Orders extends Component {

  constructor(props) {
    super(props);
    this.state={
      isLoading: false,
      alert:null,
      colorAlert: "primary",
      visible: false,
      services:[],
      imei: "",
      service_id: "",
      status: "",
      id:''
    }
  }

  componentDidMount() {
    const {categories} = this.props
    const services = filterServices(categories);
    this.setState({services})
    this.setState({isLoading: true});
    this.onSubmit();
  }

  onChange = (e) => {
    this.setState({[e.target.name]: e.target.value}, () => {
      this.onSubmit();
    });
  }

  onSubmit = () => {
    const {imei, service_id, status, id} = this.state;
    const data = {imei, service_id, status, id, u:true};
    new OrderFunctions().get(
      data,
      (data) => this.errorResponse(data),
      () => this.successResponse()
    )
  }

  successResponse = () => this.setState({isLoading: false});

  errorResponse = (data) => {
    this.setState({
      isLoading: false,
      alert: data,
      colorAlert: "danger",
      visible: true,
    }, () => {
      setTimeout(() => {
        this.onDismiss()
      }, timeOut)
    });
  }

  onDismiss = () => {this.setState({visible: !this.state.visible, colorAlert: 'primary'})}

  changeIsLoading = () => {this.setState({isLoading: !this.state.isLoading})}

  render() {
    const {orders} = this.props;
    const {isLoading, alert, colorAlert, service_id, status, imei, id} = this.state;
    let dataSend = {imei, service_id, status, id};
    return (
      <>
        <PanelHeader size="sm" />
        <div className="content ">
          <Card className={'pt-3'}>
            <Col xs={12}>
              <Card>
                {alert && <Alert color={colorAlert} isOpen={this.state.visible} toggle={() => this.onDismiss}>
                  {alert}
                </Alert>}
                <CardHeader>
                  <CardTitle tag="h4">
                    <div className={"justify-content-between align-content-between text-center"}>
                      Ordenes <Button size={'sm'} color={'primary'} onClick={() => this.componentDidMount()}>Actualizar</Button>
                    </div>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Table responsive className="table-shopping">
                    <thead >
                    <tr className="text-center">
                      <th className="text-center">
                        <Input
                          name={'id'}
                          placeholder={'ID...'}
                          value={id}
                          onChange={this.onChange}
                        />
                      </th>
                      <th>
                        <Input
                          defaultValue={''}
                          onChange={this.onChange}
                          type="select"
                          name="status"
                          id="select">
                          <option  value={""}>ALL</option>
                          <option value={"1"}>En Proceso</option>
                          <option value={"3"}>Rechazado</option>
                          <option value={"4"}>Completada</option>
                        </Input>
                      </th>
                      <th>Usuario</th>
                      <th>
                        <Input
                          onChange={this.onChange}
                          type="select"
                          name="service_id"
                          id="select">
                          <option selected value={""}>ALL</option>
                          {
                            this.state.services.map(service => {
                              return (
                                <option value={service.value}>{service.label}</option>
                              )
                            })
                          }
                        </Input>
                      </th>
                      <th>
                        <Input
                          name={'imei'}
                          placeholder={'Buscar por imei...'}
                          value={imei}
                          onChange={this.onChange}
                        />
                      </th>
                      <th>Respuesta</th>
                      <th>Fecha Encargo</th>
                      <th>Fecha Respuesta</th>
                    </tr>
                    </thead>
                    <tbody>
                    {orders.data.map((prop) => {
                      const status = badge(prop.status);
                      return (
                        <tr key={prop.id} className="text-center col-md-1 col-lg-1">
                          <td className={"col-md-1"}>
                            {prop.id}

                          </td>
                          <td>
                            <Badge color={status.color}>
                              {status.status}
                            </Badge>
                          </td>
                          <td>{prop.user.name}</td>
                          <td>{prop.service.name}</td>
                          <td>{prop.imei}</td>
                          <td>{ReactHtmlParser(prop.response)}</td>
                          <td className={"text-right"}>{moment(prop.created_at).format('YYYY-MM-D')}</td>
                          <td className={"text-right"}>{moment(prop.updated_at).format('YYYY-MM-D')}</td>
                        </tr>
                      );
                    })}
                    </tbody>
                    <tfoot>
                    <CustomPagination extraData={dataSend} type={"SET_ORDERS_HISTORY"} change={this.changeIsLoading} links={orders.links} />
                    </tfoot>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Card>
          <Loading isLoading={isLoading}  />
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    orders: state.orders.order_history,
    categories: state.userServices.userServices,
  }
}

export default connect(mapStateToProps)(Orders);