import React, {Component} from 'react';
import {
  Button,
  Card,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import Loading from "../../../components/Loading";
import ServicesFunctions from "./functions/ServicesFunctions";

class DeleteServiceModal extends Component {
  constructor() {
    super();
    this.state={
      service: [],
      isLoading: false
    }
  }

  getService = () => {
    this.setState({isLoading: true});
    new ServicesFunctions().show(
      this.props.service_id,
      data => this.successResponse(data),
      data => this.errorResponse(data),
    )
  }

  successResponse = ({data}) => this.setState({service: data, isLoading: false});

  errorResponse = () => this.setState({isLoading: false});

  onSubmit = (e) => {
    e.preventDefault();
    const data = {id: this.props.service_id,  type: "delete"}
    this.props.onSubmit(data);
    this.props.toggle()
  }
  render() {
    const {isOpen, toggle} = this.props;
    const { service} = this.state;
    return (
      <>
        <Modal
          onOpened={() => this.getService()}
          isOpen={isOpen}
          toggle={toggle}
          onClosed={() => this.setState({service: []})}
          modalClassName={"modal-primary "}
        >
          <Card className="card-plain card-login">
            <ModalHeader className="justify-content-center" toggle={toggle} tag="div">
              <div className="header text-center ">
                <h2 >
                  {`Eliminar la Categoria ${service.name}`}
                </h2>
              </div>
            </ModalHeader>
            <ModalBody className="card-content">
              <Label>
                <h2>
                  ¿Estas seguro de eliminar este servicio?
                </h2>
              </Label>
            </ModalBody>
            <ModalFooter>
              <Button className={"btn-round"} color="secondary" onClick={toggle}>
                Cerrar
              </Button>
              <Button
                className={"btn btn-dark btn-round"}
                onClick={e => this.onSubmit(e)}
                type={'submit'}
              >
                Eliminar el Servicio
              </Button>
            </ModalFooter>
            <Loading isLoading={this.state.isLoading} />
          </Card>
        </Modal>
      </>
    );
  }
}

export default DeleteServiceModal;