import React, {Component} from 'react';
import {Button, Col, Label, Modal, ModalBody, ModalHeader} from "reactstrap";
import ServicesFunctions from "./functions/ServicesFunctions";
import Select from "react-select";
import Loading from "../../../components/Loading";
import Footer from "../../../components/Footer/Footer";

class LinkApis extends Component {

  constructor() {
    super();
    this.state={
      apis:[],
      api1:[],
      api2:[],
      apiServices1:[],
      apiServices2:[],
      apiService1:[],
      apiService2:[],
      service1_id:"",
      service2_id:"",
      isLoading: false
    }
  }

  getService = () => {
    this.setState({isLoading: true});
    new ServicesFunctions().show(
      this.props.service_id,
      data => this.successResponse(data),
      data => this.errorCallback(data),
    )
    this.getApis();
  }

  errorCallback = (data) => console.log("error", data);

  getApis = () => {
    new ServicesFunctions().getApis(
      data => this.getApiSuccess(data),
    )
  }

  successResponse = ({data}) => {
    let api1 = {value: "", label: "Sin Asignar"};
    let api2 = {value: "", label: "Sin Asignar"};
    if (data.api1) {
       api1 = {value: data.api1.id, label: data.api1.name};
       new ServicesFunctions().getApi(data.api1_id, data => this.successApiService1Response(data))
    }
    if (data.api2) {
      api2 = {value: data.api2.id, label: data.api2.name};
      new ServicesFunctions().getApi(data.api2_id, data => this.successApiService2Response(data))
    }

    this.setState({
      ...this.state,
      api1,
      name: data.name,
      api2,
      service1_id: data.service1_id,
      service2_id: data.service2_id,
      isLoading: false
    });
  }

  successApiService1Response = data => {
    const {api_services} = data.data;
    let apiServices1 = [{value:"", label:"Sin Asignar"}];
    api_services.map(service => {
      return apiServices1.push({
        value: service.service_id,
        label: service.name,
      })
    })
    let apiService1 = api_services.find(service => {
      return service.service_id === this.state.service1_id
    })
    let apiServices12 = {value: "", label: "Sin Asignar" }
    if (apiService1) {
       apiServices12 = {value: apiService1.service_id, label: apiService1.name }
    }
    this.setState({apiServices1, apiService1: apiServices12})
  }

  successApiService2Response = data => {
    const {api_services} = data.data;
    let apiServices2 = [{value:"", label:"Sin Asignar"}];
    api_services.map(service => {
      return apiServices2.push({
        value: service.service_id,
        label: service.name,
      })
    })
    let apiService2 = api_services.find(service => {
      return service.service_id === this.state.service2_id
    })
    let apiServices12 = {value: "", label: "Sin Asignar" }
    if (apiService2) {
      apiServices12 = {value: apiService2.service_id, label: apiService2.name }
    }
    this.setState({apiServices2, apiService2: apiServices12})
  }

  onChangeApi1 = (data) => {
    this.setState({api1: data, apiServices1:[]})
    new ServicesFunctions().getApi(data.value, data => this.successApiService1Response(data))
  }

  onChangeApi2 = (data) => {
    this.setState({api2: data, apiServices2:[]})
    new ServicesFunctions().getApi(data.value, data => this.successApiService2Response(data))
  }

  onChangeApiService1 = (data) => this.setState({apiService1: data})

  onChangeApiService2 = (data) => this.setState({apiService2: data})

  getApiSuccess = data => {
    let apis = [{value:"", label:"Sin Asignar"}];
    data.data.map(api => {
      return apis.push({
        value: api.id,
        label: api.name,
      })
    })
    this.setState({isLoading: false, apis});
  }

  onSubmit = (e) => {
    e.preventDefault();
    const {api1, apiService1, api2, apiService2} = this.state;
    if (api1.value === "") {
      api1.value = null;
    }
    if (apiService1.value === "") {
      apiService1.value = null;
    }
    if (api2.value === "") {
      api2.value = null;
    }
    if (apiService2.value === "") {
      apiService2.value = null;
    }
    const data = {
      id: this.props.service_id,
      api1_id: api1.value,
      service1_id: apiService1.value,
      api2_id: api2.value,
      service2_id: apiService2.value,
      type: "edit"};
    this.props.onSubmit(data);
    this.props.toggle();
  }

  render() {
    const {isOpen, toggle} = this.props;
    const {apis, api1, api2,apiService1, apiService2,apiServices1, apiServices2} = this.state;
    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        onOpened={() => this.getService()}
        onClosed={() =>
          this.setState({
            apis:[],
            api1:[],
            api2:[],
            apiServices:[],
            apiService1:[],
            apiService2:[]
          })}
      >
        <ModalHeader>
          Apis
        </ModalHeader>
        <ModalBody>
          <Col>
            <Label>Api Primaria</Label>
            <Select
              className="react-select warning"
              classNamePrefix="react-select"
              closeMenuOnSelect={true}
              onSelect={(e) => console.log(e)}
              placeholder="Api Primaria"
              name="multipleSelect"
              value={api1}
              options={apis}
              onChange={(value) => this.onChangeApi1(value)}
            />
          </Col>
          {
            api1.value > 0 &&
            <Col>
              <Label>Servicio Primario</Label>
              <Select
                className="react-select warning"
                classNamePrefix="react-select"
                closeMenuOnSelect={true}
                placeholder="Api Primaria"
                name="multipleSelect"
                value={apiService1}
                options={apiServices1}
                onChange={(value) => this.onChangeApiService1(value)}
              />
            </Col>
          }
          <Col>
            <Label>Api Secundaria</Label>
            <Select
              className="react-select warning"
              classNamePrefix="react-select"
              closeMenuOnSelect={true}
              placeholder="Api Secundaria"
              name="multipleSelect"
              value={api2}
              options={apis}
              onChange={(value) => this.onChangeApi2(value)}
            />
          </Col>
          {
            api1.value > 0 &&
            <Col>
              <Label>Servicio Secundario</Label>
              <Select
                className="react-select warning"
                classNamePrefix="react-select"
                closeMenuOnSelect={true}
                placeholder="Api Secundaria"
                name="multipleSelect"
                value={apiService2}
                options={apiServices2}
                onChange={(value) => this.onChangeApiService2(value)}
              />
            </Col>
          }
          <Button
            onClick={e => this.onSubmit(e)}
            size={'sm'}
            color={"primary"}
          >
            Enviar
          </Button>
        </ModalBody>
        <Footer />
        <Loading isLoading={this.state.isLoading} />
      </Modal>
    );
  }
}

export default LinkApis;