const AuthReducer = (state = {}, actions) => {
  switch (actions.type) {
    case "SET_USERS":
      return {
        ...state,
        users: actions.payload
      };

    case "SET_USER_BILLS":
        return {
          ...state,
          userBills:actions.payload
        };

    case "SET_USER_ORDERS":
      return {
        ...state,
        userOrders:actions.payload
      };

    case "SET_USER":
      return {
        ...state,
        user: actions.payload
      };

    case "SET_USER_SERVICES":
      return {
        ...state,
        userServices: actions.payload
      };

    case "USER_EDIT":
      let us = state.users.data.map(user => {
        if (user.id === actions.payload.id) {
          return actions.payload
        } else {
          return user
        }
      })
      return {
        ...state,
        users: {
          ...state.users,
          data: us
        }
      }
    case "USER_DELETE":
      let usr = state.users.data.filter(user => {
        return user.id !== actions.payload.id;
      })
      return {
        ...state,
        users: {
          ...state.users,
          data: usr
        }
      }
    default:
      return state;
  }
};
export default AuthReducer;