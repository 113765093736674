import React, {Component} from 'react';
import PanelHeader from "../../../components/PanelHeader/PanelHeader";
import { Card, CardBody, Table, CardHeader, CardTitle, Col} from "reactstrap";
import {thead} from "../../../variables/general";
import UserFunctions from "./functions/UserFunctions";
import CustomPagination from "../../../components/pagination/Pagination";
import {connect} from "react-redux";
import moment from "moment";
import Loading from "../../../components/Loading";

class OrderUserHistory extends Component {
  constructor() {
    super();
    this.state={
      user_id:'',
      isLoading: false,
    }
  }

  componentDidMount() {
    this.setState({user_id: this.props.match.params.id, isLoading: true});
    new UserFunctions().getOrders(
      this.props.match.params.id,
      data => this.successCallback(data),
      data => this.errorCallback(data),
    )
  }
  changeIsLoading = () => this.setState({isLoading: !this.state.isLoading});

  successCallback = () => this.changeIsLoading();

  errorCallback = (data) => {
    console.log("Error: ", data)
    this.changeIsLoading();
  }

  render() {
    const {orders} = this.props;
    return (
      <>
        <PanelHeader size="sm" />
        <div className="content ">
          <Card className={'pt-3'}>
            <Col xs={12}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">
                    <div className={"justify-content-between align-content-between text-center"}>
                      Ordenes
                    </div>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                    <tr>
                      <th>Servicio</th>
                      <th>Status</th>
                      <th>Imei</th>
                      <th>Respuesta</th>
                      <th>Precio</th>
                      <th className="text-right">Fecha</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                      orders.data.map(prop => {
                        return (
                          <tr key={prop.id}>
                            <td>{prop.service.name}</td>
                            <td>{prop.status}</td>
                            <td>{prop.imei}</td>
                            <td>{prop.response}</td>
                            <td>${prop.price}</td>
                            <td className={'text-right'}>{moment(prop.created_at).format('MMMM Do YYYY, h:mm:ss a')}</td>
                          </tr>
                        )
                      })
                    }
                    </tbody>
                    <tfoot>
                    <CustomPagination type={"SET_ADMIN_USER_ORDERS"} change={this.changeIsLoading} links={orders.links} />
                    </tfoot>
                  </Table>
                </CardBody>
              </Card>
              <Loading isLoading={this.state.isLoading}/>
            </Col>
          </Card>
        </div>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    orders: state.orders.adminUserOrder
  }
}

export default connect(mapStateToProps)(OrderUserHistory);