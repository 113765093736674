import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import axios from "axios";
import cookie from "js-cookie";
import store from "../../store";

export default class CustomPagination extends React.Component{

  compilePagination= () => {
    const links = this.props.links;
    return links.map(l => {
      let label = l.label;
      if (label === "&laquo; Previous") {
        label = "<"
      }
      if (label === "Next &raquo;") {
        label = ">"
      }

      return (
        <PaginationItem key={`label-pagination-${label}`} disabled={!l.url} active={l.active}>
          <PaginationLink tag="span" onClick={() => this.changePagination(l.url)}>
            {label}
          </PaginationLink>
        </PaginationItem>
        )
    })
  }

  changePagination = async (url) => {
    this.props.change()
    const {extraData} = this.props;
    //?imei=${data.imei}&service_id=${data.service_id}&status=${data.status}&id=${data.id}
    await axios.get(`${url}&imei=${extraData.imei}&service_id=${extraData.service_id}&status=${extraData.status}`, {
      headers:{
        Authorization: `Bearer ${cookie.get("token")}`,
        'Content-Type': 'application/x-www-form-urlencoded',
        "X-Requested-With": "XMLHttpRequest",
      }
    }).then(({data}) => {
      store.dispatch({
        type: this.props.type,
        payload: data.data
      });
    })
    this.props.change()
  }
  render(){
    return(
      <Pagination>
        {this.compilePagination()}
      </Pagination>
    );
  }
}