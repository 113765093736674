const CategoryReducer = (state = {}, actions) => {
  switch (actions.type) {
    case "SET_CATEGORIES":
      return {
        ...state,
        categories: actions.payload
      };
    case "SET_CATEGORY":
      return {
        ...state,

      };
    case "CATEGORY_EDIT":
      let cate = state.categories.data.map(category => {
        if (category.id === actions.payload.id) {
          return actions.payload
        } else {
          return category
        }
      })
      return {
        ...state,
        categories: {
          ...state.categories,
          data: cate
        }
      }
    case "CATEGORY_DELETE":
      let categ = state.categories.data.filter(category => {
        return category.id !== actions.payload.id;
      })
      return {
        ...state,
        categories: {
          ...state.categories,
          data: categ
        }
      }
    default:
      return state;
  }
};
export default CategoryReducer;