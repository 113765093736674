import Base from "../../../../functions/Base";
import store from "../../../../store";

export default class UserFunctions extends Base {
  get(errorResponse, successResponse){
    this.instance.get('/admin-users').then(({data}) => {
      store.dispatch({
        type: "SET_USERS",
        payload: data.data
      });
      successResponse()
    }).catch(err => {
      if (err.response && err.response.data) {
        errorResponse(err.response.data.error)
      } else {
        errorResponse("Error inesperado, intentelo de nuevo");
      }
    });
  }
  // SET_USER_ORDERS

  getBills(id, successCallback, errorCallback){
    this.instance.get(`/admin-users/${id}/bills`)
      .then(({data}) => {
        store.dispatch({
          type: "SET_ADMIN_USER_BILLS",
          payload: data.data
        });
        successCallback(data);
      })
      .catch(({response}) => {
        console.log(response);
        errorCallback(response);
      })
  }

  getOrders(id, successCallback, errorCallback){
    this.instance.get(`/admin-users/${id}/orders`)
      .then(({data}) => {
        store.dispatch({
          type: "SET_ADMIN_USER_ORDERS",
          payload: data.data
        });
        successCallback(data);
      })
      .catch(({response}) => {
        console.log(response);
        errorCallback(response);
      })
  }

  show(id, successResponse, errorResponse){
    this.instance.get(`/admin-users/${id}`).then(({data}) => {
      successResponse(data)
    }).catch(({response}) => {
      if (response && response.data) {
        errorResponse(response.data.error)
      } else {
        errorResponse("Error inesperado, intentelo de nuevo");
      }
    });
  }

  store (data, successResponse, errorResponse){
    this.instance.post('/admin-users', data).then(({data}) => {
      store.dispatch({
        type: "SET_USERS",
        payload: data.data
      });
      successResponse("Usuario creado correctamente.")
    }).catch(({response}) => {
      if (response && response.data) {
        if (typeof response.data.error !== "object") {
          errorResponse(response.data.error)
        } else {
          let errors = response.data.error
          let object = Object.values(errors)
          let res = '';
          object.map((key, index) => {
            if (index === 0) {
              res = key.toString();
            }
          })
          errorResponse(res);
        }
      } else {
        errorResponse("Error inesperado, intentelo de nuevo");
      }
    })
  }

  update ( data, successResponse,  errorResponse){
    this.instance.patch(`/admin-users/${data.id}`, data).then(({data}) => {
      store.dispatch({
        type: "USER_EDIT",
        payload: data.data
      });
      successResponse("Usuario Editado.", data);
    }).catch(({response}) => {
      if (response && response.data) {
        if (typeof response.data.error !== "object") {
          errorResponse(response.data.error)
        } else {
          let errors = response.data.error
          let object = Object.values(errors)
          let res = '';
          object.map((key, index) => {
            if (index === 0) {
              res = key.toString();
            }
          })
          errorResponse(res);
        }
      } else {
        errorResponse("Error inesperado, intentelo de nuevo");
      }
    })
  }

  delete (data, successResponse, errorResponse){
    this.instance.delete(`admin-users/${data.id}`)
      .then(({data}) => {
        store.dispatch({
          type: "USER_DELETE",
          payload: data.data
        });
        successResponse("Usuario eliminado correctamente.");
      })
      .catch(({response}) => {
        if (response && response.data) {
          errorResponse(response.data.error)
        } else {

          errorResponse("Error inesperado, intentelo de nuevo");
        }
      })
  }

  getCategories(successResponse,  errorResponse) {
    this.instance.get("admin-categories-all")
      .then(({data}) => {
        successResponse(data);
      })
      .catch(({response}) => {
        errorResponse("Error al cargar las categorias");
      })
  }

  getUserServices(id, successCallback, errorCallback){
    this.instance.get(`/custom-prices?id=${id}`)
      .then(({data}) => {
        store.dispatch({
          type: "SET_USER_SERVICES",
          payload: data
        });
        successCallback()
      })
      .catch(({response}) => {
        console.log(response);
        errorCallback()
      })
  }

  changeCustomPrices(id, data, successCallback, errorCallback) {
    this.instance.patch(`admin-custom-services/${id}`, data)
      .then(({data}) => {
        store.dispatch({
          type: "SET_USER_SERVICES",
          payload: data
        });
        successCallback();
      })
      .catch(({response}) => {
        console.log("response", response)
        errorCallback();
      })
  }

  resetUser(id, successResponse, errorResponse){
    this.instance.patch(`admin-reset-users/${id}`)
      .then(({data}) => {
        return successResponse(data.data)
      })
      .catch(({response}) => {
        console.log(response);
        return errorResponse(response.data)
      });
  }
}