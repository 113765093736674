import React, {Component} from 'react';
import {Button, Input, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
const initialState = {
  response:'',
  order:[],
  status:3
}
class SuccessOrderModal extends Component {
  constructor() {
    super();
    this.state={
      ...initialState
    }
  }

  componentDidMount() {
    const {order} = this.props;
    this.setState({order});
  }

  onSubmit = (e) => {
    e.preventDefault();
    const data = {response: this.state.response,status:4, order: this.props.order}
    this.props.onRejectOrder(data)
    this.props.toggle()
  }

  onChange = (e) => this.setState({[e.target.name]: e.target.value});

  render() {
    const {toggle, isOpen, order, response} = this.props;
    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        onClosed={() => this.setState({...initialState})}
      >
        <ModalHeader>
          Contestar Orden {order.imei}
        </ModalHeader>
        <ModalBody>
          <Input
            value={response}
            name={'response'}
            onChange={this.onChange}
            placeholder={"Dejar en blanco para respuesta default..."}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            color={'danger'}
            onClick={toggle}
            size={'sm'}
          >
            Cancelar
          </Button>
          <Button
            size={'sm'}
            onClick={(e) => this.onSubmit(e)}
          >
            Contestar Orden
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default SuccessOrderModal;