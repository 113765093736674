
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  FormGroup, CardFooter, Alert,

} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import {connect} from "react-redux";
import Loading from "../../components/Loading";
import ProfileFunctions from "./functions/ProfileFunctions";
import {timeOut} from "../../functions/Base";

class User extends React.Component {

  constructor() {
    super();
    this.state={
      name:"",
      email:"",
      api_key:"",
      password:"",
      password_confirmation:"",
      isLoading: false,
      colorAlert: 'primary',
      visible: false,
      alert:''
    }
  }

  componentDidMount() {
    this.setState({isLoading: true})
    const { name, email, api_key} = this.props.user;
    this.setState({name, email, api_key})
    new ProfileFunctions().get(
      () => this.successResponse(),
      () => this.errorResponse()
    )
  }

  onSubmitGeneral = e => {
    this.setState({isLoading: true})
    e.preventDefault();
    const {name, email} = this.state;
    const data = {id: this.props.user.id, name, email};
    new ProfileFunctions().update(
      data,
      data => this.successResponse(data),
      data => this.errorResponse(data)
    )
  }

  onSubmitPassword = e => {
    this.setState({isLoading: true})
    e.preventDefault();
    const {password, password_confirmation} = this.state;
    const data = {id: this.props.user.id, password, password_confirmation };
    new ProfileFunctions().update(
      data,
      data => this.successResponse(data),
      data => this.errorResponse(data)
    )
  }

  successResponse = (alert) => {
    this.setState({
      isLoading: false,
      alert,
      visible: true
    },  () => {
      setTimeout(() => {
        this.onDismiss()
      }, timeOut)
    });
  }

  errorResponse = (alert) => {
    this.setState({
      isLoading: false,
      alert,
      colorAlert: "danger",
      visible: true,
    }, () => {
      setTimeout(() => {
        this.onDismiss()
      }, timeOut)
    });
  }

  onDismiss = () => {this.setState({visible: !this.state.visible, colorAlert: 'primary'})}

  onChange = e => this.setState({[e.target.name]: e.target.value});

  render() {
    const {user} = this.props;
    const {name, email, password, password_confirmation, api_key, isLoading, alert, colorAlert} = this.state;
    return (
      <>
        <PanelHeader size="sm"/>
        <div className="content">
          {alert && <Alert color={colorAlert} isOpen={this.state.visible} toggle={() => this.onDismiss}>
            {alert}
          </Alert>}
          <Row>
            <Col md="8">
              <Card>
                <Form>
                  <CardHeader>
                    <h5 className="title">Mi Perfil</h5>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label>Nombre</label>
                          <Input
                            name={'name'}
                            placeholder="Nombre de Usuario"
                            type="text"
                            value={name}
                            onChange={this.onChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pl-1" md="6">
                        <FormGroup>
                          <label htmlFor="exampleInputEmail1">
                            Correo Electronico
                          </label>
                          <Input
                            placeholder="Email"
                            type="email"
                            name={"email"}
                            value={email}
                            onChange={this.onChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label>ApiKey</label>
                          <Input
                            placeholder="Company"
                            type="text"
                            name={"api_key"}
                            value={api_key}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pl-1" md="6">
                        <FormGroup>
                          <label>Creditos Disponibles</label>
                          <Input
                            defaultValue="Andrew"
                            placeholder="Last Name"
                            type="text"
                            value={user.credits}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <Button
                      color={'primary'}
                      size={'sm'}
                      onClick={e => this.onSubmitGeneral(e)}
                    >
                      Editar Perfil
                    </Button>
                  </CardFooter>
                </Form>
              </Card>
                <Card>
                  <Form>
                    <CardHeader>
                      <h5 className="title">Actualizar Contraseña</h5>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col className="pr-1" md="6">
                          <label htmlFor="exampleInputEmail1">
                            Contraseña
                          </label>
                          <Input
                            placeholder="Contraseña"
                            type="password"
                            name={"password"}
                            value={password}
                            onChange={this.onChange}
                          />
                        </Col>
                        <Col className="pr-1" md="6">
                          <label htmlFor="exampleInputEmail1">
                            Confirmacion
                          </label>
                          <Input
                            placeholder="confirma la contraseña"
                            type="password"
                            name={"password_confirmation"}
                            value={password_confirmation}
                            onChange={this.onChange}
                          />
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter>
                      <Button
                        size={'sm'}
                        color={'primary'}
                        onClick={e => this.onSubmitGeneral(e)}
                      >
                        Editar Contraseña
                      </Button>
                    </CardFooter>
                  </Form>
                </Card>
            </Col>
            <Col md="4">
              <Card className="card-user">
                <div className="image">
                  <img alt="..." src={require("assets/img/bg5.jpg").default}/>
                </div>
                <CardBody>
                  <div className="author">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="avatar border-gray"
                        src={require("assets/img/fenox/img.png").default}
                      />
                      <h5 className="title">{user.name}</h5>
                    </a>
                    <p className="description">Conexion DHRU</p>
                  </div>
                  <p className={"description text-center"}>URL:</p>
                  <p className={"description text-center"}>https://api.desblokeame.com</p>
                  <p className={"description text-center"}>ApiKey:</p>
                  <p className={"description text-center"}>{user.api_key}</p>
                  <p className={"description text-center"}>Username:</p>
                  <p className={"description text-center"}>{user.name}</p>
                </CardBody>
                <hr/>
                <div className="button-container">
                  <Button
                    className="btn-icon btn-round"
                    color="neutral"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    size="lg"
                  >
                    <i className="fab fa-facebook-square"/>
                  </Button>
                  <Button
                    className="btn-icon btn-round"
                    color="neutral"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    size="lg"
                  >
                    <i className="fab fa-twitter"/>
                  </Button>
                  <Button
                    className="btn-icon btn-round"
                    color="neutral"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    size="lg"
                  >
                    <i className="fab fa-google-plus-square"/>
                  </Button>
                </div>
              </Card>
            </Col>
          </Row>
         <Loading isLoading={isLoading} />
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user
  }
}

export default connect(mapStateToProps)(User);
