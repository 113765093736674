import React, {Component} from 'react';
import {
  Button,
  Card, Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import CategoryFunctions from "./functions/CategoryFunctions";
import Loading from "../../../components/Loading";
import Switch from "react-bootstrap-switch";
import Select from "react-select";

class EditCategoryModal extends Component {
  constructor() {
    super();
    this.state={
      name:'',
      active: 'false',
      isLoading: false,
      services: [],
      servicesId: [],
    }
  }

  getCategory = () => {
    this.setState({isLoading: true});
    new CategoryFunctions().show(
      this.props.category_id,
      data => this.successResponse(data),
      data => this.errorResponse(data),
    )
    this.getServices();
  }

  getServices = () => {
    new CategoryFunctions().getServices(data => this.successServices(data))
  }

  successServices = (data) => {
    let services = [];
    data.data.map(service => {
      return services.push({
        value: service.id,
        label: service.name,
      })
    })
    this.setState({services});
  }

  successResponse = ({data}) => {
    let active = '';
    if (data.active === "false" ){
      active = false;
    } else {
      active = true;
    }
    let services = [];
    data.services.map(service => {
      return services.push({
        value: service.id,
        label: service.name,
      })
    })
    this.setState({servicesId: services, name: data.name, active: active, isLoading: false});
  }

  errorResponse = (data) => {
    this.setState({isLoading: false});
    console.log(data);
  }

  onChange = (e) => {
    this.setState({[e.target.name]: e.target.value});
  }

  onChangeSelect = (data) => {
    let ids = this.state.servicesId;
    ids.push(data[0])
    this.setState({servicesId: data})
  }

  onChangeActive = (e) => {
    this.setState({active: e.state.value});
  }

  onSubmit = (e) => {
    e.preventDefault();
    const {name, active, servicesId} = this.state;
    const data = {id: this.props.category_id,name, active: active.toString(),  type: "edit", services: servicesId}
    this.props.onSubmit(data);
    this.props.toggle()
  }
  render() {
    const {isOpen, toggle} = this.props;
    const {name, active, servicesId, services} = this.state;
    return (
      <>
        <Modal
          onOpened={() => this.getCategory()}
          isOpen={isOpen}
          toggle={toggle}
          onClosed={() => this.setState({name: '', active: false, servicesId:[], services:[]})}
          modalClassName={"modal-primary "}
        >
          <Card className="card-plain card-login">
            <ModalHeader className="justify-content-center" toggle={toggle} tag="div">
              <div className="header text-center ">
                <h2 >
                  {`Editar Categoria ${name}`}
                </h2>
              </div>
            </ModalHeader>
            <ModalBody className="card-content">
              <form className="form" onSubmit={e => this.onSubmit(e)}>
                <Col >
                  <Label className={"text-white"}>Nombre</Label>
                  <Input
                    type="text"
                    placeholder="Nombre de la Categoria"
                    name={'name'}
                    value={name}
                    onChange={this.onChange}
                    autoComplete={'off'}
                  />
                </Col>
                <Col className={"mt-2"}>
                  <Label className={"text-white"}> Activo</Label><br />
                  <Switch
                    defaultValue={active}
                    name={'active'}
                    onChange={this.onChangeActive}
                    value={active}
                  />
                </Col>
                <Col>
                  <Select
                    className="react-select warning"
                    classNamePrefix="react-select"
                    isMulti
                    closeMenuOnSelect={false}
                    placeholder="Multiple Select"
                    name="multipleSelect"
                    value={servicesId}
                    options={services}
                    onChange={(value) => this.onChangeSelect(value)}
                  />
                </Col>
              </form>
            </ModalBody>
            <ModalFooter>
              <Button className={"btn-round"} color="secondary" onClick={toggle}>
                Cerrar
              </Button>
              <Button
                disabled={!this.state.name}
                className={"btn btn-dark btn-round"}
                onClick={e => this.onSubmit(e)}
                type={'submit'}
              >
                Guardar Cambios
              </Button>
            </ModalFooter>
            <Loading isLoading={this.state.isLoading} />
          </Card>
        </Modal>
      </>

    );
  }
}

export default EditCategoryModal;