import React, {Component} from 'react';
import {
  Button,
  Card,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import CategoryFunctions from "./functions/CategoryFunctions";
import Loading from "../../../components/Loading";

class DeleteCategoryModal extends Component {
  constructor() {
    super();
    this.state={
      category: [],
      isLoading: false
    }
  }

  getCategory = () => {
    this.setState({isLoading: true});
    new CategoryFunctions().show(
      this.props.category_id,
      data => this.successResponse(data),
      data => this.errorResponse(data),
    )
  }

  successResponse = ({data}) => this.setState({category: data, isLoading: false});


  errorResponse = () => this.setState({isLoading: false});

  onSubmit = (e) => {
    e.preventDefault();
    const data = {id: this.props.category_id,  type: "delete"}
    this.props.onSubmit(data);
    this.props.toggle()
  }
  render() {
    const {isOpen, toggle} = this.props;
    const { category} = this.state;
    return (
      <>
        <Modal
          onOpened={() => this.getCategory()}
          isOpen={isOpen}
          toggle={toggle}
          onClosed={() => this.setState({category: []})}
          modalClassName={"modal-primary "}
        >
          <Card className="card-plain card-login">
            <ModalHeader className="justify-content-center" toggle={toggle} tag="div">
              <div className="header text-center ">
                <h2 >
                  {`Eliminar la Categoria ${category.name}`}
                </h2>
              </div>
            </ModalHeader>
            <ModalBody className="card-content">
              <Label>
                <h2>
                  ¿Estas seguro de eliminar esta categoria?
                </h2>
              </Label>
            </ModalBody>
            <ModalFooter>
              <Button className={"btn-round"} color="secondary" onClick={toggle}>
                Cerrar
              </Button>
              <Button
                className={"btn btn-dark btn-round"}
                onClick={e => this.onSubmit(e)}
                type={'submit'}
              >
                Eliminar la Categoria
              </Button>
            </ModalFooter>
            <Loading isLoading={this.state.isLoading} />
          </Card>
        </Modal>
      </>

    );
  }
}

export default DeleteCategoryModal;