
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Switch, Redirect } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/now-ui-dashboard.scss?v=1.5.0";
import "assets/css/demo.css";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import {Provider} from "react-redux";
import GuestRoute from "./components/GuestRoute";
import AuthRoute from "./components/AuthRoute";
import store from "./store";

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Switch>
        <GuestRoute
          path="/auth"
          component={props => <AuthLayout {...props} />}
        />
        <AuthRoute
          path="/admin"
          component={props => <AdminLayout {...props} />}
        />
        <Redirect to="/admin/dashboard" />
      </Switch>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
