import Base from "../../../functions/Base";
import store from "../../../store";
import cookie from "js-cookie";

export default class ProfileFunctions extends Base {
  get(successResponse, errorResponse){
    this.instance.get('/profile').then(({data}) => {
      cookie.set("token", data.data.token);
      store.dispatch({
        type: "SET_LOGIN",
        payload: data.data
      });
      successResponse('')
    }).catch(({response}) => {
      errorResponse("Error inesperado, intentelo de nuevo");
    });
  }

  onlyGet(){
    this.instance.get('/profile').then(({data}) => {
      cookie.set("token", data.data.token);
      store.dispatch({
        type: "SET_LOGIN",
        payload: data.data
      });
    });
  }

  logout(){
    this.instance.get('/logout').then(({data}) => {
      cookie.set("token", data.data.token);
      cookie.set("token", "");
      store.dispatch({
        type: "SET_LOGOUT",
        payload: data.data
      });
    });
  }

  update ( data, successResponse,  errorResponse){
    this.instance.patch(`/customer-update-profile/${data.id}`, data).then(({data}) => {
      store.dispatch({
        type: "SET_LOGIN",
        payload: data.data
      });
      return successResponse("Usuario actualizado.");
    }).catch(({response}) => {
      if (response && response.data) {
        if (typeof response.data.error !== "object") {
          errorResponse(response.data.error)
        } else {
          let errors = response.data.error
          let object = Object.values(errors)
          let res = '';
          object.map((key, index) => {
            if (index === 0) {
              return res = key.toString();
            }
          })
          return errorResponse(res);
        }
      } else {
        return errorResponse("Error inesperado, intentelo de nuevo");
      }
    })
  }
}