import React, {Component} from 'react';
import PanelHeader from "../../../components/PanelHeader/PanelHeader";
import {Alert, Card, CardBody, CardHeader, CardTitle, Col, Table} from "reactstrap";
import {tbody, thead, BILL_HEAD} from "../../../variables/general";
import CustomPagination from "../../../components/pagination/Pagination";
import {connect} from "react-redux";
import Loading from "../../../components/Loading";
import {timeOut} from "../../../functions/Base";
import BillFunctions from "./functions/BillsFunctions";
import moment from "moment";
import 'moment/locale/es';

class Bills extends Component {

  constructor(props) {
    super(props);
    this.state={
      isLoading: false,
      alert:null,
      colorAlert: "primary",
      visible: false,
    }
  }

  componentDidMount() {
    this.setState({isLoading: true});
    new BillFunctions().get(
      (data) => this.errorResponse(data),
      () => this.successResponse()
    )
  }

  changeIsLoading = () => {
    this.setState({isLoading: !this.state.isLoading});
  }

  successResponse = () => this.setState({isLoading: false});

  errorResponse = (data) => {
    this.setState({
      isLoading: false,
      alert: data,
      colorAlert: "danger",
      visible: true,
    }, () => {
      setTimeout(() => {
        this.onDismiss()
      }, timeOut)
    });
  }

  onDismiss = () => {this.setState({visible: !this.state.visible, colorAlert: 'primary'})}

  render() {
    const {bills} = this.props;
    const {isLoading, alert, colorAlert} = this.state;
    return (
      <>
        <PanelHeader size="sm" />
        <div className="content ">
          <Card className={'pt-3'}>
            <Col xs={12}>
              <Card>
                {alert && <Alert color={colorAlert} isOpen={this.state.visible} toggle={() => this.onDismiss}>
                  {alert}
                </Alert>}
                <CardHeader>
                  <CardTitle tag="h4">
                    <div className={"justify-content-between align-content-between text-center"}>
                      Facturas
                    </div>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                    <tr>
                      {BILL_HEAD.map((prop, key) => {
                        if (key === BILL_HEAD.length - 1)
                          return (
                            <th key={key} className="text-right">
                              {prop}
                            </th>
                          );
                        return <th key={key}>{prop}</th>;
                      })}
                    </tr>
                    </thead>
                    <tbody>
                    {bills.data.map((prop, key) => {
                      return (
                        <tr key={prop.id}>
                          <td>{prop.user.name}</td>
                          <td>${prop.amount}</td>
                          <td className={"text-right"}>{moment(prop.created_at).format('MMMM Do YYYY, h:mm:ss a')}</td>
                        </tr>
                      );
                    })}
                    </tbody>
                    <tfoot>
                    <CustomPagination type={"SET_BILLS"} change={this.changeIsLoading} links={bills.links} />
                    </tfoot>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Card>
          <Loading isLoading={isLoading}  />
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    bills: state.bills.bills
  }
}

export default connect(mapStateToProps)(Bills);