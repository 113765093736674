import Base from "../../../../functions/Base";
import store from "../../../../store";

export default class OrderFunctions extends Base {
  get(data, errorResponse, successResponse){
    this.instance.get(`/admin-orders?imei=${data.imei}&service_id=${data.service_id}&status=${data.status}&id=${data.id}`)
      .then(({data}) => {

      store.dispatch({
        type: "SET_ORDERS_HISTORY",
        payload: data.data
      });
      successResponse()
    })
      .catch(err => {
      if (err.response && err.response.data) {
        errorResponse(err.response.data.error)
      } else {
        errorResponse("Error inesperado, intentelo de nuevo");
      }
    });
  }


  responseOrder(data, successCallback, errorCallback){
    this.instance.patch(`/admin-orders/${data.order.id}`, data)
      .then(({data}) => {
        store.dispatch({
          type: "SET_ORDERS_HISTORY",
          payload: data.data
        });
        successCallback()
      })
      .catch(({response}) => {
        errorCallback();
        console.log(response);
      })
  }
}