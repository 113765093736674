
import React from "react";
// react plugin used to create charts
import { Line } from "react-chartjs-2";
// react plugin for creating vector maps

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Badge,
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";

import DashboardFunctions from "./functions/DashboardFunctions";
import ReactHtmlParser from "react-html-parser";
import Loading from "../../components/Loading";
import UserServiceFunctions from "../users/services/functions/UserServiceFunctions";
import {badge} from "../../functions/statusOrder";

// Chart


class Dashboard extends React.Component {
  constructor() {
    super();
    this.state={
      credits:'',
      orders:[],
      graph:[],
      processCredits: '',
      processOrders: '',
      purchasedCredits: '',
      rejectedOrders: '',
      successOrders: '',
      totalCredits: '',
      totalOrders: '',
      isLoading: false
    }
  }

  componentDidMount() {
    this.setState({isLoading: true});
    new UserServiceFunctions().get(
      data => this.success(data),
      data => this.error(data),
    )
    this.getData()
  }

  success = () => {}
  error = () => {}

  getData = () => {
    new DashboardFunctions().get(
      data => this.successResponse(data)
    )
  }

  successResponse = data => {
    console.log(data.data)
    const {
      processCredits,
      processOrders,
      purchasedCredits,
      rejectedOrders,
      successOrders,
      totalCredits,
      totalOrders,
      credits,
      graph,
      orders
    } = data.data
    this.setState({
      processCredits,
      processOrders,
      purchasedCredits,
      rejectedOrders,
      successOrders,
      totalCredits,
      totalOrders,
      credits,
      graph,
      orders,
      isLoading: false
    })

  }

  labels = () => {
    let label = [];
    this.state.graph.map((day) => {
      label.push(day.month);
    })
    return label;
  }

  dataChart = () => {
    let label = [];
    this.state.graph.map((day) => {
      label.push(day.count);
    })
    return label;
  }

  customField = (order) => {
    let field = "";
    if (order.imei !== "" && order.imei) {
      field = order.imei;
    } else if (order.custom_fields) {
      let buff = new Buffer(order.custom_fields, 'base64');
      let base64ToString = buff.toString('ascii');
      let myArray = base64ToString.split(',');

      let custom = Array(JSON.parse(myArray))
      custom.map((prop, index) => {
        if (index === 0 && prop) {
          field = `${Object.values(prop)}`
        } else {
          if (prop){
            field = `${field} - ${Object.values(prop)}`
          } else {
            field=""
          }

        }
      })
    }
    field = field.replace(",", "<br />")
    return field
  }

  render() {
    console.log(this.state.graph)
    const dashboardChart = {
      data: (canvas) => {
        const ctx = canvas.getContext("2d");
        var chartColor = "#FFFFFF";
        var gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
        gradientStroke.addColorStop(0, "#80b6f4");
        gradientStroke.addColorStop(1, chartColor);
        var gradientFill = ctx.createLinearGradient(0, 200, 0, 50);
        gradientFill.addColorStop(0, "rgba(128, 182, 244, 0)");
        gradientFill.addColorStop(1, "rgba(255, 255, 255, 0.14)");

        return {
          labels:
           this.labels(),
          datasets: [
            {
              label: "Ordenes Completas",
              borderColor: chartColor,
              pointBorderColor: chartColor,
              pointBackgroundColor: "#2c2c2c",
              pointHoverBackgroundColor: "#2c2c2c",
              pointHoverBorderColor: chartColor,
              pointBorderWidth: 1,
              pointHoverRadius: 7,
              pointHoverBorderWidth: 2,
              pointRadius: 5,
              fill: true,
              backgroundColor: gradientFill,
              borderWidth: 2,
              tension: 0.4,
              data: this.dataChart(),
            },
          ],
        };
      },
      options: {
        layout: {
          padding: {
            left: 20,
            right: 20,
            top: 0,
            bottom: 0,
          },
        },
        maintainAspectRatio: false,
        plugins: {
          tooltips: {
            backgroundColor: "#fff",
            titleFontColor: "#333",
            bodyFontColor: "#666",
            bodySpacing: 4,
            xPadding: 12,
            mode: "nearest",
            intersect: 0,
            position: "nearest",
          },
          legend: {
            position: "bottom",
            fillStyle: "#FFF",
            display: false,
          },
        },
        scales: {
          y: {
            ticks: {
              fontColor: "rgba(255,255,255,0.4)",
              fontStyle: "bold",
              beginAtZero: true,
              maxTicksLimit: 5,
              padding: 10,
            },
            grid: {
              drawTicks: true,
              drawBorder: false,
              display: true,
              color: "rgba(255,255,255,0.1)",
              zeroLineColor: "transparent",
            },
          },
          x: {
            grid: {
              display: false,
              color: "rgba(255,255,255,0.1)",
            },
            ticks: {
              padding: 10,
              fontColor: "rgba(255,255,255,0.4)",
              fontStyle: "bold",
            },
          },
        },
      },
    };
    return (
      <>
        <PanelHeader
          size="lg"
          content={
            <Line
              data={dashboardChart.data}
              options={dashboardChart.options}
            />
          }
        />
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <Card className="card-stats card-raised">
                <CardBody className={"bg-primary"}>
                  <Row>
                    <Col md="3">
                      <div className="statistics bg-success">
                        <div className="info">
                          <h3 className="info-title">{this.state.credits && this.state.credits.toFixed(2)}</h3>
                          <h6 className="info-title">Creditos disponibles</h6>
                        </div>
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="statistics bg-warning">
                        <div className="info">
                          <h3 className="info-title">
                            {this.state.processCredits && this.state.processCredits.toFixed(2)}
                          </h3>
                          <h6 className="info-title">Creditos En Proceso</h6>
                        </div>
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="statistics bg-danger">
                        <div className="info">
                          <h3 className="info-title">{this.state.purchasedCredits && this.state.purchasedCredits.toFixed(2)}</h3>
                          <h6 className="info-title">Creditos Gastados</h6>
                        </div>
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="statistics" style={{
                        backgroundColor:"#F207F5"
                      }}>
                        <div className="info">
                          <h3 className="info-title">{this.state.totalCredits && this.state.totalCredits.toFixed(2)}</h3>
                          <h6 className="info-title">Total Recargado</h6>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col md="3">
                      <div className="statistics bg-success">
                        <div className="info">
                          <h3 className="info-title">{this.state.successOrders}</h3>
                          <h6 className="info-title">Ordenes Contestadas</h6>
                        </div>
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="statistics bg-warning">
                        <div className="info">
                          <h3 className="info-title">{this.state.processOrders}</h3>

                          <h6 className="info-title">Ordenes En Proceso</h6>
                        </div>
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="statistics bg-danger">
                        <div className="info">
                          <h3 className="info-title">
                            {this.state.rejectedOrders}
                          </h3>
                          <h6 className="info-title">Ordenes Rechazadas</h6>
                        </div>
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="statistics" style={{
                        backgroundColor: "#F207F5"
                      }}>
                        <div className="info">
                          <h3 className="info-title">{this.state.totalOrders}</h3>
                          <h6 className="info-title">Ordenes Totales</h6>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Ultimas 10 Ordenes</CardTitle>
                </CardHeader>
                <CardBody>
                  <Table responsive className="table-shopping">
                    <thead>
                    <tr className={"text-center"}>
                      <th className="text-center">ID</th>
                      <th>Estatus</th>
                      <th>Servicio</th>
                      <th>Costo</th>
                      <th >IMEI</th>
                      <th className="text-right">Respuesta</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                      this.state.orders && this.state.orders.map(prop => {
                        const status = badge(prop.status);
                        const customField = this.customField(prop)
                        return (
                          <tr key={`table-dashboard-${prop.id}`}>
                            <td>{prop.id}</td>
                            <td>
                              <Badge color={status.color}>
                                {status.status}
                              </Badge>
                            </td>
                            <td>{prop.service.name}</td>
                            <td>{prop.price}</td>
                            <td className={"text-center"}>{ReactHtmlParser(customField)}</td>
                            <td className={'text-right'}>{ReactHtmlParser(prop.response)}</td>
                          </tr>
                        )
                      })
                    }
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Loading isLoading={this.state.isLoading} />
        </div>
      </>
    );
  }
}

export default Dashboard;
