import React, {Component} from 'react';
import PanelHeader from "../../../components/PanelHeader/PanelHeader";
import {Card, Input, CardBody, Table, CardHeader, CardTitle, Col, Badge, Button, Row, CardFooter} from "reactstrap";
import {thead} from "../../../variables/general";
import CustomPagination from "../../../components/pagination/Pagination";
import {connect} from "react-redux";
import UserHistoryFunctions from "./functions/UserHistoryFunctions";
import ShowOrderModal from "./ShowOrderModal";
import { filterUserServices} from "../../../functions/filterServices";
import ProfileFunctions from "../../Pages/functions/ProfileFunctions";
import {statusOrder} from "../../../functions/statusOrder";
import ReactHtmlParser from 'react-html-parser';


class UserOrderHistory extends Component {
  constructor() {
    super();
    this.state={
      isLoading: false,
      imei: '',
      status:'',
      service_id: '',
      services:[],
      showModal:false,
      order:[]
    }
  }

  componentDidMount() {

    this.servicesState();
    this.setState({ isLoading: true});
    this.onSubmit();
    new ProfileFunctions().onlyGet();
  }

  servicesState =  () => {
    const {categories} = this.props
    const services = filterUserServices(categories);
    this.setState({services})
  }

  onSubmit = () => {
    const {imei, service_id, status} = this.state;
    const data = {imei, service_id, status};
    new UserHistoryFunctions().getOrders(
      data,
      data => this.successCallback(data),
      data => this.errorCallback(data),
    )
  }

  onChange = (e) => {
    this.setState({[e.target.name]: e.target.value}, () => {
      this.onSubmit();
    });

  }

  changeIsLoading = () => this.setState({isLoading: false});

  successCallback = () => {
    this.setState({isLoading: false})
  }
  errorCallback = (data) => {
    console.log("Error: ", data)
    this.setState({isLoading: false})
  }

  toggleShow = (order) => {
    if (order) {
      this.setState({showModal: !this.state.showModal, order});
    } else {
      this.setState({showModal: !this.state.showModal, order:[]});
    }
  }

  customField = (order) => {
    let field = "";
    if (order.imei !== "" && order.imei) {
      field = order.imei;
    } else if (order.custom_fields) {
      let buff = new Buffer(order.custom_fields, 'base64');
      let base64ToString = buff.toString('ascii');
      let myArray = base64ToString.split(',');

      let custom = Array(JSON.parse(myArray))
      custom.map((prop, index) => {
        if (index === 0 && prop) {
          field = `${Object.values(prop)}`
        } else {
          if (prop){
            field = `${field} - ${Object.values(prop)}`
          } else {
            field="";
          }

        }
      })
    }
    field = field.replace(",", "<br />")

    return field
  }

  render() {
    const {orders} = this.props;
    const {imei, service_id, status, order} = this.state;
    let dataSend = {imei, service_id, status};
    return (
      <>
        <PanelHeader size="sm" />
        <div className="content ">
          <Row>
            <Col xs={12} md={12}>
              <Card className={'pt-3'}>
                <CardHeader>
                  <CardTitle tag="h4" className={"text-center"}>
                      Historial de Ordenes <Button className={"text-right ml-4"} size={'sm'} color={'primary'} onClick={() => this.componentDidMount()}>Actualizar</Button>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Table responsive className="table-shopping">
                    <thead >
                    <tr className={"text-center"}>
                      <th className="text-center" >ID</th>
                      <th >
                        <Input
                          defaultValue={''}
                          onChange={this.onChange}
                          type="select"
                          name="status"
                          id="select"
                        >
                          <option  value={""}>ALL</option>
                          <option value={"1"}>En Proceso</option>
                          <option value={"3"}>Rechazado</option>
                          <option value={"4"}>Completada</option>
                        </Input>
                      </th>
                      <th>
                        <Input
                          onChange={this.onChange}
                          type="select"
                          name="service_id"
                          id="select">
                          <option selected value={""}>ALL</option>
                          {
                            this.state.services.map(service => {
                              return (
                                <option value={service.value}>{service.label}</option>
                              )
                            })
                          }
                        </Input>
                      </th>
                      <th >Costo</th>
                      <th >
                        <Input
                          name={'imei'}
                          placeholder={'Buscar por imei...'}
                          value={imei}
                          onChange={this.onChange}
                        />
                      </th>
                      <th className="text-right">Respuesta</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                      orders.data.map(prop => {
                        const status = statusOrder(prop.status)
                        const customField = this.customField(prop)
                        return (
                          <tr className="text-center" key={`user-history-${prop.id}`}>
                            <td  className={"text-center"} onClick={() => this.toggleShow(prop)}>{prop.id}</td>
                            <td className={"text-center"} onClick={() => this.toggleShow(prop)}>
                              {
                                <Badge color={status.color}>
                                  {status.text}
                                </Badge>
                              }
                            </td>
                            <td className={"text-left col-md-4"} onClick={() => this.toggleShow(prop)}>{prop.service.name}</td>
                            <td className={"text-center"} onClick={() => this.toggleShow(prop)}>${prop.price.toFixed(2)}</td>
                            <td className={"text-center"}>{ReactHtmlParser(customField)}</td>
                            <td className={'text-right'}>{ReactHtmlParser(prop.response)}</td>
                          </tr>
                        )
                      })
                    }
                    </tbody>
                  </Table>
                </CardBody>
                <CardFooter >
                  <CustomPagination
                    extraData={dataSend}
                    type={"SET_USER_ORDERS"}
                    change={this.changeIsLoading}
                    links={orders.links}
                  />
                </CardFooter>
                <ShowOrderModal
                  isOpen={this.state.showModal}
                  toggle={(data) => this.toggleShow(data)}
                  order={order}
                />
          </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    orders: state.users.userOrders,
    categories: state.userServices.userServices,
  }
}

export default connect(mapStateToProps)(UserOrderHistory);