import React, {Component} from 'react';
import PanelHeader from "../../../components/PanelHeader/PanelHeader";
import {Alert, Badge, Button, Card, CardBody, CardHeader, CardTitle, Col, Table} from "reactstrap";
import {tbody, thead} from "../../../variables/general";
import CustomPagination from "../../../components/pagination/Pagination";
import {connect} from "react-redux";
import Loading from "../../../components/Loading";
import {timeOut} from "../../../functions/Base";
import 'moment/locale/es';
import OrderFunctions from "./functions/OrderFunctions";
import RejectOrderModal from "./RejectOrderModal";
import SuccessOrderModal from "./SuccessOrderModal";
import {badge} from "../../../functions/statusOrder";

class OrderPending extends Component {

  constructor(props) {
    super(props);
    this.state={
      isLoading: false,
      alert:null,
      colorAlert: "primary",
      visible: false,
      services:[],
      status: "5",
      imei:'',
      service_id:"",
      rejectOrderModal: false,
      successOrderModal: false,
      order:[]
    }
  }

  componentDidMount() {
    this.onSubmit();
  }

  toggleReject = (order) => {
    if (!order) {
      order= []
    }
    this.setState({rejectOrderModal: !this.state.rejectOrderModal, order})
  }

  toggleSuccess = (order) => {
    if (!order) {
      order= []
    }
    this.setState({successOrderModal: !this.state.successOrderModal, order})
  }

  onChange = (e) => {
    this.setState({[e.target.name]: e.target.value}, () => {
      this.onSubmit();
    });
  }

  onSubmit = () => {
    this.setState({isLoading: true});
    const {  status, imei, service_id} = this.state;
    const data = {status, imei, service_id};
    new OrderFunctions().get(
      data,
      (data) => this.errorResponse(data),
      () => this.successResponse()
    )
  }

  successResponse = () => this.setState({isLoading: false});

  errorResponse = (data) => {
    this.setState({
      isLoading: false,
      alert: data,
      colorAlert: "danger",
      visible: true,
    }, () => {
      setTimeout(() => {
        this.onDismiss()
      }, timeOut)
    });
  }

  onRejectOrder = (data) => {
    this.setState({isLoading: true});
    if (data.response === "") {
      data.response = "No Encontrado";
    }
    new OrderFunctions().responseOrder(
      data,
        data => this.success(data),
        data => this.error(data))
  }

  success = (data) => {
    this.setState({isLoading: false});  }

  error = (data) => {
    this.setState({isLoading: false});
    console.log(data)
  }

  onCompleteOrder = (data) => {
    this.setState({isLoading: true});    if (data.response === "") {
      data.response = "Listo";
    }
    new OrderFunctions().responseOrder(
      data,
      data => this.success(data),
      data => this.error(data)
    )
  }

  onDismiss = () => {this.setState({visible: !this.state.visible, colorAlert: 'primary'})}

  changeIsLoading = () => {this.setState({isLoading: !this.state.isLoading})}

  render() {
    const {orders} = this.props;
    const {isLoading, alert, colorAlert} = this.state;
    const {imei, service_id, status, id} = this.state;
    let dataSend = {imei, service_id, status, id};
    return (
      <>
        <PanelHeader size="sm" />
        <div className="content ">
          <Card className={'pt-3'}>
            <Col xs={12}>
              <Card>
                {alert && <Alert color={colorAlert} isOpen={this.state.visible} toggle={() => this.onDismiss}>
                  {alert}
                </Alert>}
                <CardHeader>
                  <CardTitle tag="h4">
                    <div className={"justify-content-between align-content-between text-center"}>
                      Ordenes <Button size={'sm'} color={'primary'} onClick={() => this.componentDidMount()}>Actualizar</Button>
                    </div>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                    <tr>
                      <th>Estatus</th>
                      <th>Usuario</th>
                      <th>Servicio</th>
                      <th>Imei</th>
                      <th>Respuesta</th>
                      <th>Rechazar</th>
                      <th>Contestar</th>
                    </tr>
                    </thead>
                    <tbody>
                    {orders.data.map((prop) => {
                      const status = badge(prop.status);
                      return (
                        <tr key={prop.id}>
                          <td>
                            <Badge color={status.color}>
                              {status.status}
                            </Badge>

                            {
                              prop.error &&
                                <>
                                  <br />
                                  <small color={"danger"} >
                                    {prop.error}
                                  </small>
                                </>

                            }
                          </td>
                          <td>{prop.user.name}</td>
                          <td>{prop.service.name}</td>
                          <td>{prop.imei}</td>

                          <td>{prop.response}</td>
                          <td className={"text-right"}>
                            <Button
                              color={'danger'}
                              size={'sm'}
                              onClick={() => this.toggleReject(prop)}
                            >
                              Rechazar
                            </Button>
                          </td>
                          <td className={"text-right"}>
                            <Button
                              size={'sm'}
                              color={'success'}
                              onClick={() => this.toggleSuccess(prop)}
                            >
                              Contestar
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                    </tbody>
                    <tfoot>
                    <CustomPagination extraData={dataSend} type={"SET_ORDERS_HISTORY"} change={this.changeIsLoading} links={orders.links} />
                    </tfoot>
                  </Table>
                  <RejectOrderModal
                    isOpen={this.state.rejectOrderModal}
                    toggle={(data) => this.toggleReject(data)}
                    order={this.state.order}
                    onRejectOrder={data => this.onRejectOrder(data)}
                  />
                  <SuccessOrderModal
                    isOpen={this.state.successOrderModal}
                    toggle={(data) => this.toggleSuccess(data)}
                    order={this.state.order}
                    onRejectOrder={data => this.onCompleteOrder(data)}
                  />
                </CardBody>
              </Card>
            </Col>
          </Card>

          <Loading isLoading={isLoading}  />
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    orders: state.orders.order_history,
  }
}

export default connect(mapStateToProps)(OrderPending);