import React, {Component} from 'react';
import {Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import Switch from "react-bootstrap-switch";

class ChangeActiveModal extends Component {

  constructor() {
    super();
    this.state={
      price:'',
      active: '',
      service_id: "",
      user_id:"",
    }
  }

  initialState = (service) => {

    let {price, active, service_id, user_id} = service.pivot;
    if (active === "false") {
      active = false;
    } else {
      active = true;
    }
    this.setState({price, active, service_id, user_id});
  }

  onChange = e => {
    this.setState({[e.target.name]: e.target.value});
  }
  onSubmit = (e) => {
    e.preventDefault();
    let { user_id, service_id, price, active} = this.state;
    if (active === true) {
      active = "true";
    } else {
      active = "false";
    }
    const data = {user_id, service_id, price, active}
    this.props.onSubmit(data);
    this.props.toggle();
  }

  onChangeActive = (e) => {
    this.setState({active: e.state.value});
  }

  render() {
    const {isOpen, toggle, service} = this.props;
    const {active, price} = this.state;
    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        onOpened={() => this.initialState(service)}
      >
        <ModalHeader className={"text-center"}>
          {
            service && service.pivot && service.pivot.active === "true" ?
              `¿Desactivar este servicio ${service && service.name} a este usuario?` :
              `¿Activar este servicio ${service && service.name} a este usuario?`
          }
        </ModalHeader>
        <ModalBody>
          <Label>Precio</Label>
          <Input
            value={price}
            placeholder={"Precio"}
            name={"price"}
            onChange={this.onChange}
          />

          <Label className={"text-white"}> Activo</Label><br />
          <Switch
            defaultValue={active}
            name={'active'}
            onChange={this.onChangeActive}
            value={active}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={toggle}
            color={"danger"}
          >
            Cerrar
          </Button>
          <Button
            onClick={(e) => this.onSubmit(e)}
            color={"primary"}
          >
            Editar
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default ChangeActiveModal;