import React, {Component} from 'react';
import {Alert, Badge, Button, Modal, ModalBody, ModalHeader, Table} from "reactstrap";
import Footer from "../../Footer/Footer";
import axios from "axios";
import {api, timeOut} from "../../../functions/Base";
import cookie from "js-cookie";
import Loading from "../../Loading";
import {statusOrder} from "../../../functions/statusOrder";
import ShowOrderModal from "../../../views/users/history/ShowOrderModal";
import ServicesFunctions from "../../../views/admin/services/functions/ServicesFunctions";
import {SERVICE_HEAD} from "../../../variables/general";
import CreateServiceModal from "../../../views/admin/services/CreateServiceModal";
import EditServiceModal from "../../../views/admin/services/EditServiceModal";
import LinkApis from "../../../views/admin/services/LinkApis";
import DeleteServiceModal from "../../../views/admin/services/DeleteServiceModal";
import ResetPriceModal from "../../../views/admin/services/ResetPriceModal";

class SearchModal extends Component {

  constructor() {
    super();
    this.state={
      isLoading: false,
      orders:[],
      services:[],
      users:[],
      showModal:false,
      order:[],
      alert:null,
      colorAlert: "primary",
      visible: false,
      createModal: false,
      editModal: false,
      deleteModal: false,
      resetModal: false,
      linkModal: false,
      service_id:""
    }
  }

  onOpened = async () => {
    const {search} = this.props;
    this.setState({isLoading: true});
    await axios.get(`${api}/global-search?global_search=${search}`, {
      headers: {
        Authorization: `Bearer ${cookie.get("token")}`,
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      }
    }).then(({data}) => {
      const {users, services, orders} = data.data;
      this.setState({users, services, orders});
    });
    this.setState({isLoading: false});
  }

  onClickUser = (e, user) => {
    this.props.history.push(`/admin/admin-user/${user.id}`, user);
     this.props.toggle(e);
  }

  toggleShow = (order) => {
    if (order) {
      this.setState({showModal: !this.state.showModal, order});
    } else {
      this.setState({showModal: !this.state.showModal, order:[]});
    }
  }

  sendServiceRequest = ( data) => {
    this.setState({isLoading: true})
    switch (data.type){
      case "create":
        new ServicesFunctions().store(data,
          (data) => this.successResponse(data),
          (data) => this.errorResponse(data),
        )
        break;
      case "edit":
        data.type = data.serviceType;
        new ServicesFunctions().update(data,
          (message, data) => this.successResponse(message, data),
          (data) => this.errorResponse(data),
        )
        break;
      case "delete":
        new ServicesFunctions().delete(data,
          (data) => this.successResponse(data),
          (data) => this.errorResponse(data),
        )
        break;
      default:
        break;
    }
  }

  successResponse = (message) => {
    this.setState({isLoading: false});
    this.setState({
      isLoading: false,
      alert: message,
      visible: true
    },  () => {
      setTimeout(() => {
        this.onDismiss()
      }, timeOut)
    });
  }

  errorResponse = (data) => {
    this.setState({isLoading: false});
    this.setState({
      isLoading: false,
      alert: data,
      colorAlert: "danger",
      visible: true,
    }, () => {
      setTimeout(() => {
        this.onDismiss()
      }, timeOut)
    });
  }

  onDismiss = () => {
    this.setState({visible: !this.state.visible, colorAlert: 'primary'})
  }

  toggleCreateModal = () => this.setState({createModal: !this.state.createModal});

  toggleEditModal = service_id => this.setState({editModal: !this.state.editModal, service_id});

  toggleDeleteModal = service_id => this.setState({deleteModal: !this.state.deleteModal, service_id});

  toggleResetModal = service_id => this.setState({resetModal: !this.state.resetModal, service_id});

  toggleLinkModal = service_id => this.setState({linkModal: !this.state.linkModal, service_id});

  onResetPrices = id => {
    this.setState({isLoading: true});
    new ServicesFunctions().resetServices(
      id,
      data => this.successResponse(data),
      data => this.errorResponse(data)
    )
  }

  render() {
    const {isOpen, toggle} = this.props
    const {services, users, orders, order, colorAlert, alert} = this.state
    return (
      <React.Fragment>
        <Modal
          isOpen={isOpen}
          toggle={toggle}
          onOpened={this.onOpened}
          size={'lg'}
          onClosed={
            () => this.setState({
              isLoading: false,
              orders:[],
              services:[],
              users:[],
              showModal:false,
              order:[]
            }
          )}
        >
          {alert && <Alert color={colorAlert} isOpen={this.state.visible} toggle={() => this.onDismiss}>
            {alert}
          </Alert>}
          <ModalHeader>

            Busqueda Global
          </ModalHeader>
          <ModalBody>
            {
              users.length > 0 &&
              <>
                <h2>Usuarios</h2>
                <Table responsive className="table-shopping">
                  <thead>
                  <tr>
                    <td>Nombre</td>
                    <td>email</td>
                    <td>Acciones</td>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    users.map(user => {
                      return (
                        <tr>
                          <td>{user.name}</td>
                          <td>{user.email}</td>
                          <td>
                            <Button
                              size={"sm"}
                              color={"primary"}
                              onClick={(e) => this.onClickUser(e, user)}
                            >
                              Ver
                            </Button>
                          </td>
                        </tr>
                      )
                    })
                  }
                  </tbody>
                </Table>
              </>
            }
            {
              services.length > 0 &&
              <>
                <h2>Servicios</h2>
                <Table responsive className="table-shopping">
                  <thead>
                  <tr>
                    {SERVICE_HEAD.map((prop, key) => {
                      if (key === SERVICE_HEAD.length - 1)
                        return (
                          <th key={key} className="">
                            {prop}
                          </th>
                        );
                      return <th key={key}>{prop}</th>;
                    })}
                    <th  className="text-right">
                      {'Acciones'}
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    services.map(service => {
                      return (
                        <tr>
                          <td>{service.name}</td>
                          <td>{service.time}</td>
                          <td>{service.cost}</td>
                          <td>{service.price}</td>
                          <td>
                            <Button
                              size={'sm'}
                              className="rounded"
                              color={service.active === "true" ? 'success' : 'danger'}
                            >
                              {service.active === "true" ? "Activo" : "Inactivo"}
                            </Button>
                          </td>
                          <td className="text-right">
                            <Button
                              className="btn-icon mr-2"
                              color="warning"
                              id="tooltip26024663"
                              size="sm"
                              type="button"
                              onClick={() => this.toggleLinkModal(service.id)}
                            >
                              <i className="now-ui-icons design_bullet-list-67" />
                            </Button>
                            <Button
                              className="btn-icon"
                              color="info"
                              id="tooltip26024663"
                              size="sm"
                              type="button"
                              onClick={() => this.toggleResetModal(service.id)}
                            >
                              <i className="now-ui-icons arrows-1_refresh-69" />
                            </Button>
                            <Button
                              className="btn-icon ml-2"
                              color="info"
                              id="tooltip26024663"
                              size="sm"
                              type="button"
                              onClick={() => this.toggleEditModal(service.id)}
                            >
                              <i className="now-ui-icons ui-2_settings-90" />
                            </Button>
                          </td>
                        </tr>
                      )
                    })
                  }
                  </tbody>
                </Table>
              </>
            }
            {
              orders.length > 0 &&
              <>
                <h2>Ordenes</h2>
                <Table responsive className="table-shopping">
                  <thead>
                  <tr>
                    <td>id</td>
                    <td>Imei</td>
                    <td>Respuesta</td>
                    <td>Status</td>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    orders.map(order => {
                      const status = statusOrder(order.status)
                      return (
                        <tr key={`order-search-${order.id}`} onClick={() => this.toggleShow(order)}>
                          <td>{order.id}</td>
                          <td>{order.imei}</td>
                          <td>{order.response}</td>
                          <td>
                            <Badge color={status.color}>
                              {status.text}
                            </Badge>
                          </td>
                        </tr>
                      )
                    })
                  }
                  </tbody>
                </Table>
              </>
            }
          </ModalBody>
          <Footer />
          <Loading isLoading={this.state.isLoading} />
          <ShowOrderModal
            isOpen={this.state.showModal}
            toggle={(data) => this.toggleShow(data)}
            order={order}
          />
          <CreateServiceModal
            isOpen={this.state.createModal}
            toggle={() => this.toggleCreateModal()}
            onSubmit={( data) => this.sendServiceRequest( data)}
          />
          <EditServiceModal
            isOpen={this.state.editModal}
            toggle={(data) => this.toggleEditModal(data)}
            onSubmit={( data) => this.sendServiceRequest( data)}
            service_id={this.state.service_id}
          />
          <LinkApis
            isOpen={this.state.linkModal}
            toggle={(data) => this.toggleLinkModal(data)}
            onSubmit={( data) => this.sendServiceRequest( data)}
            service_id={this.state.service_id}
          />
          <DeleteServiceModal
            isOpen={this.state.deleteModal}
            toggle={(data) => this.toggleDeleteModal(data)}
            onSubmit={( data) => this.sendServiceRequest( data)}
            service_id={this.state.service_id}
          />
          <ResetPriceModal
            isOpen={this.state.resetModal}
            toggle={(data) => this.toggleResetModal(data)}
            service_id={this.state.service_id}
            onSubmit={(data) => this.onResetPrices(data)}
          />
        </Modal>
      </React.Fragment>

    );
  }
}

export default SearchModal;