import React, {Component} from 'react';
import PanelHeader from "../../../components/PanelHeader/PanelHeader";
import { Button, Table, Card, CardBody, CardHeader, CardTitle, Col, Badge} from "reactstrap";
import UserFunctions from "./functions/UserFunctions";
import {connect} from "react-redux";
import ChangeActiveModal from "./ChangeActiveModal";
import Loading from "../../../components/Loading";

class UserCustomPrices extends Component {
  constructor() {
    super();
    this.state={
      id:"",
      price: "",
      activeModal: false,
      service:[],
      isLoading: false
    }
  }

  componentDidMount() {
    this.setState({isLoading: true})
    this.setState({id: this.props.location.state.id})
    new UserFunctions().getUserServices(
      this.props.location.state.id,
      data => this.successResponse(data),
      data => this.errorResponse(data),
    )
  }

  toggleActiveModal = service => this.setState({activeModal: !this.state.activeModal, service});

  successResponse = () => {
    this.setState({isLoading: false})
  }

  errorResponse = () => {
    this.setState({isLoading: false})
  }

  status = service => {
    let status = {color: "danger", text: "Inactivo"}
    if (service.pivot.active === "true") {
      status = {color: "success", text: "activo"};
    }
    return status;
  }

  onchangeData = (data) => {
    this.setState({isLoading:true});
    new UserFunctions().changeCustomPrices(
      data.user_id,
      data,
      data => this.successResponse(data),
      data => this.errorResponse(data)
    )
  }


  render() {
    const {services} = this.props;
    return (
      <div>
        <PanelHeader size="sm" />
        <div className="content ">
          <Card className={'pt-3'}>
            <Col xs={12}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">
                    <div className={"justify-content-between align-content-between text-center"}>
                      Servicios de
                    </div>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        <th>Nombre</th>
                        <th>Costo</th>
                        <th>Precio Normal</th>
                        <th>Activo</th>
                        <th>Precio Custom</th>
                        <th className={"text-right"}> Editar </th>
                      </tr>
                    </thead>
                    <tbody>
                    {
                      services.data.map(service => {
                        const status = this.status(service);
                        return (
                          <tr key={service.id}>
                            <td>{service.name}</td>
                            <td>{service.cost}</td>
                            <td>
                              {service.price}
                            </td>
                            <td>
                              <Badge
                                color={status.color}
                              >
                                {status.text}
                              </Badge>
                            </td>
                            <td>{service.pivot.price}</td>
                            <td className={"text-right"}>
                              <Button
                                color={"primary"} size={"sm"}
                                onClick={() => this.toggleActiveModal(service)}
                              >
                                Editar
                              </Button>
                            </td>
                          </tr>
                        )
                      })
                    }
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
              <ChangeActiveModal
                isOpen={this.state.activeModal}
                toggle={(data) => this.toggleActiveModal(data)}
                service={this.state.service}
                onSubmit={(data) => this.onchangeData(data)}
              />
            </Col>
          </Card>
          <Loading isLoading={this.state.isLoading} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    services: state.users.userServices
  }
}

export default connect(mapStateToProps) (UserCustomPrices);