import Base from "../../../../functions/Base";
import store from "../../../../store";

export default class BillFunctions extends Base {
  get(errorResponse, successResponse){
    this.instance.get('/admin-bills').then(({data}) => {
      store.dispatch({
        type: "SET_BILLS",
        payload: data.data
      });
      successResponse()
    }).catch(err => {
      if (err.response && err.response.data) {
        errorResponse(err.response.data.error)
      } else {
        errorResponse("Error inesperado, intentelo de nuevo");
      }
    });
  }
}